/**
 * Contract Grid for editing Contract Facts
 *
 * @author Todd Hay
 *
 */

// Libraries
import React, { FC, useCallback, useEffect, useState } from "react";

// Fields to display in the form
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MUIDataTable from "mui-datatables";
import Alert from "@mui/material/Alert";
import deepEqual from "deep-equal";
import { archive, getAll, insert, update } from "../../utils/dataAccess";
import { MuiForm5 as Form } from "@rjsf/material-ui";
import makeStyles from "@mui/styles/makeStyles";
import { contractColumnsFactory } from "../../model/contract/ContractColumnsFactory";
import { ContractObjectFieldTemplate } from "./subComponents/ContractObjectFieldTemplate";
import { contractUiSchema } from "./subComponents/ContractUiSchema";
import {
  redirect,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import AddIconButton from "../shared/AddIconButton";
import { ISubmitEvent } from "@rjsf/core";
import {
  Approval,
  Contract,
  severityString,
  Snack,
  UserType,
} from "../../utils/types";
import dateUtils from "../../utils/dateUtils";
import ConfirmDialog from "./subComponents/ConfirmDialog";

const vodSchema = require("../../model/contract/vodContract.json");
const tvodSchema = require("../../model/contract/tvodContract.json");
const epgSchema = require("../../model/contract/epgContract.json");
const demandSchema = require("../../model/contract/demandContract.json");
const ssaiSchema = require("../../model/contract/ssaiContract.json");
const distributionSchema = require("../../model/contract/distributionContract.json");

const useStyles = makeStyles((theme) => ({
  rjsf: {
    margin: 8,
  },
  formButtons: {
    textAlign: "center",
    margin: theme.spacing(2),
  },
  pageContainer1: {
    marginTop: "30px",
    marginBottom: "30px",
  },
}));

interface Props {
  user: UserType;
  embed?: boolean;
  selectPartner?: any;
  showTitle?: boolean;
  allowDelete: boolean;
}

export const ContractGrid: FC<Props> = (props): JSX.Element => {
  const { user, embed, selectPartner, allowDelete, showTitle = true } = props;
  type IdParams = {
    contractId: string;
  };

  const { contractId } = useParams<IdParams>();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [allContracts, setAllContracts] = useState([]);
  const [contractToEdit, setContractToEdit] = useState<Contract | null>(null);
  const [currentSchema, setCurrentSchema] = useState(vodSchema);
  const [contractType, setContractType] = useState<string | null>("vod");
  const [contractToDelete, setContractToDelete] = useState<number | null>(null);

  const [snack, setSnack] = useState<Snack>({
    show: false,
    alert: "Nothing to see here...",
    severity: "success",
  });

  const initialColumns = [
    "partner_name",
    "name",
    "partner_id",
    "type",
    "effectiveDate",
    "contractSource",
    "dateAdded",
    "actions",
  ];
  const teams = [
    "Business Development",
    "Finance",
    "Content Operations",
    "Ad Operations",
  ];

  const initialApprovalsArray: Approval[] = teams.map((x) => ({
    userEmail: null,
    isApproved: "unreviewed",
    userTeam: x,
    dateReviewed: null,
    comments: null,
  }));

  const classes = useStyles();

  const getContracts = useCallback(() => {
    const whereClause: string[] = ["active"];
    if (selectPartner) {
      whereClause.push(`pid = ${selectPartner}`);
    }
    getAll("contracts", ["*", "p.partner AS partner_name"], whereClause)
      .then((x) => {
        const migratedContracts = x.map(mapFromDatabase);
        setAllContracts(migratedContracts);
        setLoading(false);
        if (contractId) {
          onEdit(parseInt(contractId), migratedContracts);
        }
      })
      .catch((err) => {
        setSnackDetails("Error getting contracts.", "error");
        console.log(err);
      });
  }, [selectPartner, contractId]);

  // useEffect(() => {

  // }, []);

  const mapFromDatabase = (contract: { approvals: any; isPending: null }) => {
    const newContractApprovals = contract.approvals;
    for (let i = 0; i < newContractApprovals.length; i++) {
      switch (newContractApprovals[i].isApproved) {
        case true:
          newContractApprovals[i].isApproved = "approved";
          break;
        case false:
          newContractApprovals[i].isApproved = "disapproved";
          break;
        case null:
          newContractApprovals[i].isApproved = "unreviewed";
          break;
      }
    }

    return {
      ...contract,
      approvals: newContractApprovals,
      isPending:
        typeof contract.isPending === "undefined" || contract.isPending === null
          ? false
          : contract.isPending,
    };
  };

  const mapToDatabase = (contract: Contract) => {
    contract.approvals.map((x: Approval) => {
      switch (x.isApproved) {
        case "approved":
          x.isApproved = true;
          x.userEmail = user.profileObj.email;
          x.dateReviewed = new Date().toISOString().slice(0, -1);
          break;
        case "disapproved":
          x.isApproved = false;
          x.userEmail = user.profileObj.email;
          x.dateReviewed = new Date().toISOString().slice(0, -1);
          break;
        case "unreviewed":
          x.isApproved = null;
          x.userEmail = null;
          x.dateReviewed = null;
          break;
        default:
          console.log("unknown isApproved state");
          break;
      }
    });
  };

  useEffect(() => {
    getContracts();
  }, [getContracts]);

  const setSnackDetails = (alert: string, severity: severityString) => {
    setSnack({
      show: true,
      severity: severity,
      alert,
    });
  };

  const onAddNew = () => {
    const today = new Date();
    const endOfYear = new Date(
      today.getFullYear() + 1,
      today.getMonth(),
      today.getDate()
    );
    if (location.pathname.includes("partners")) {
      const partnerId = location.pathname.split("/")[2];
      setContractToEdit({
        uid: null,
        pid: parseInt(partnerId),
        effectiveDate: today.toISOString().slice(0, 10),
        endDate: endOfYear.toISOString().slice(0, 10),
        approvals: initialApprovalsArray,
      });
    } else {
      setContractToEdit({
        uid: null,
        effectiveDate: today.toISOString().slice(0, 10),
        endDate: endOfYear.toISOString().slice(0, 10),
        approvals: initialApprovalsArray,
      });
    }
  };

  const handleFormClose = (formUpdatedOrSubmitted: boolean) => {
    if (formUpdatedOrSubmitted) {
      getContracts();
    }
    setContractToEdit(null);
    setContractType("vod");
  };

  const emptyUnneededValues = (formTerms: {
    shareType: string;
    inventoryShare: number;
    revenueShare: number;
  }) => {
    switch (formTerms.shareType) {
      case "revenue":
      case "revenue - partner":
        formTerms.inventoryShare = 0;
        break;
      case "inventory - plex":
      case "inventory - partner":
        formTerms.revenueShare = 0;
        break;
      case "cpm":
      case "fixed":
        formTerms.inventoryShare = 0;
        formTerms.revenueShare = 0;
        break;
      default:
        console.log("unknown shareType state");
        break;
    }
  };

  const handleSubmit = async (
    e: ISubmitEvent<Contract>,
    nativeEvent: React.FormEvent<HTMLFormElement>
  ) => {
    const formData = e.formData;

    if (formData.uid === null) {
      formData.new = true;
    }
    setSubmissionLoading(true);
    if (!formData) {
      console.error("No formData");
      setSubmissionLoading(false);
      setContractToEdit(null);
      setSnackDetails("Cancelled", "info");
      return;
    }

    emptyUnneededValues(formData.terms);
    mapToDatabase(formData);

    const totalApprovals = formData.approvals.reduce(
      (c: number, x: { isApproved: any }) => (x.isApproved ? 1 : 0) + c,
      0
    );

    const totalReviews = formData.approvals.reduce(
      (c: number, x: { isApproved: any }) => (x.isApproved != null ? 1 : 0) + c,
      0
    );

    if (totalReviews === 4) {
      formData.isPending = false;
      if (totalApprovals === 4) {
        formData.fullyApproved = true;
      }
    }

    const amendedContract: Contract = allContracts.filter(
      (x: Contract) => formData.amendedContractId === x.uid
    )[0];
    if (
      amendedContract !== undefined &&
      formData.effectiveDate &&
      formData.endDate &&
      amendedContract.endDate &&
      amendedContract.effectiveDate
    ) {
      if (
        !(
          formData?.effectiveDate > amendedContract?.endDate &&
          formData.endDate > formData.effectiveDate
        )
      ) {
        setSnackDetails(
          "Warning: these dates overlap a related contract.",
          "warning"
        );
        setSubmissionLoading(false);
        return;
      }
    }

    // Check to see if record has changed
    if (!deepEqual(formData, contractToEdit)) {
      formData.lastEditedBy = user.profileObj.email;
      if (formData.new) {
        insert("contracts", formData)
          .then(() => {
            setSnackDetails("Record Saved", "success");
            setSubmissionLoading(false);
            if (location.pathname?.slice(1, 8) === "partner") {
              navigate(0);
            }
            redirect(`/partners/${formData.pid}`);
          })
          .catch((err) => {
            setSnackDetails("Record not inserted", "error");
            console.log("recordGrid Catch");
            console.dir(err);
            setSubmissionLoading(false);
          });
      } else {
        update("contracts", formData, contractToEdit)
          .then(() => {
            setSnackDetails("Record Saved", "success");
            setSubmissionLoading(false);
            if (location.pathname?.slice(1, 8) === "partner") {
              navigate(0);
            }
            redirect(`/partners/${formData.pid}`);
          })
          .catch((err) => {
            setSnackDetails("Update Error", "error");
            setSubmissionLoading(false);
            // An API error or partial failure occurred.
            console.log(err);
            if (err.name === "PartialFailureError") {
              // Some rows failed to insert, while others may have succeeded.

              // err.errors (object[]):
              // err.errors[].row (original row object passed to `insert`)
              console.dir("errors object", err.errors[0]);
              console.log("reason code", err.errors[0].errors[0].reason);
              console.log("message", err.errors[0].errors[0].message);
              // err.errors[].errors[].message
              setSnackDetails(
                `Insert Error: ${err.errors[0].errors[0].message}`,
                "error"
              );
            }
          });
      }
    } else {
      // Record hasn't changed - user cancelled.
      setSnackDetails("No changes made. Operation cancelled.", "info");
      setSubmissionLoading(false);
    }
    setContractType("vod");
    getContracts();
  };

  const handleSnackClose = () => {
    setSnack({ show: false, alert: null, severity: "success" });
  };

  const onEdit = (
    passedContractId: number,
    passedContracts?: readonly Contract[] | undefined
  ) => {
    if (!contractId) {
      navigate(`/contracts/${passedContractId}`);
    }
    const contract = (passedContracts || allContracts).filter(
      (x: Contract) => x.uid === passedContractId
    )[0];
    const amendedContract: Contract = allContracts.filter(
      (x: Contract) => contract.amendedContractId === x.uid
    )[0];
    if (
      contract &&
      amendedContract &&
      amendedContract.endDate &&
      amendedContract.effectiveDate
    ) {
      if (
        contract.effectiveDate &&
        contract.effectiveDate > amendedContract?.endDate &&
        contract.endDate &&
        contract.endDate > contract.effectiveDate
      ) {
        console.log("no overlap");
      } else {
        setSnackDetails(
          "Warning: these dates overlap a related contract.",
          "warning"
        );
      }
    }
    setSchema(contract);
    setContractToEdit(contract);
  };

  const onAmend = (passedContractId: number, passedContracts?: any) => {
    const oldContract = (passedContracts || allContracts).filter(
      (x: { uid: number }) => x.uid === passedContractId
    )[0];
    const oldEndDate = new Date(oldContract.endDate);
    const newEndDate = new Date(
      oldEndDate.getFullYear() + 1,
      oldEndDate.getMonth(),
      oldEndDate.getDate(),
      oldEndDate.getHours()
    );
    oldEndDate.setDate(newEndDate.getDate() + 1);
    setContractToEdit({
      ...oldContract,
      effectiveDate: oldEndDate.toISOString().slice(0, 10),
      endDate: newEndDate.toISOString().slice(0, 10),
      new: true,
      amendment: true,
      uid: null,
      amendedContractId: oldContract.uid,
      approvals: initialApprovalsArray,
    });
  };

  const onCopy = (
    passedContractId: number,
    passedContracts?: Contract[] | undefined
  ) => {
    const copiedContract = (passedContracts || allContracts).filter(
      (x) => x.uid === passedContractId
    )[0];
    setContractToEdit({
      ...copiedContract,
      new: undefined,
      uid: null,
      partner_id: undefined,
      dateAdded: undefined,
      lastEdited: undefined,
      lastEditedBy: undefined,
      amendedContractId: undefined,
      approvals: initialApprovalsArray,
    });
  };

  const onDelete = (passedContractId: number | null) => {
    if (contractToDelete == null) {
      console.log("no contract to delete");
      return;
    }
    const deletedContract = allContracts.filter(
      (x: { uid: number }) => x.uid === passedContractId
    )[0];
    archive("contracts", deletedContract)
      .then(() => {
        getContracts();
        setSnackDetails("Record Deleted", "success");
        setSubmissionLoading(false);
      })
      .catch((err) => {
        console.log("Error deleting record");
        console.dir(err);
        setSnackDetails("Record not deleted", "error");
        setSubmissionLoading(false);
      });
    setContractToDelete(null);
  };

  const localColumns = localStorage.getItem("contractGrid");
  let displayedColumns;
  if (localColumns != null) {
    const localColumnsObject: { columns: any } = JSON.parse(localColumns);
    displayedColumns = localColumnsObject.columns.filter(
      (obj: any) => obj.display == "true"
    );
  }

  const generatedColumns = contractColumnsFactory(
    onEdit,
    onAmend,
    onCopy,
    embed,
    allowDelete,
    setContractToDelete
  ).map((x) => ({
    ...x,
    options: {
      ...x.options,
      display:
        localColumns && displayedColumns.length > 0
          ? undefined
          : initialColumns.indexOf(x.name) >= 0,
    },
  }));

  const validate = (formData: Contract, errors: any) => {
    // Check for duplicate ID on new contracts, or when partner_id has changed.
    if (formData.new || !(formData.partner_id === contractToEdit?.partner_id)) {
      const checkForDuplicates = (o: { partner_id: string | null }) => {
        if (o.partner_id == null) {
          return false;
        }
        return (
          formData.partner_id?.toLowerCase() === o.partner_id.toLowerCase() &&
          !formData.amendedContractId
        );
      };
      const duplicateExists = allContracts.some(checkForDuplicates);
      if (duplicateExists) {
        errors.partner_id.addError("Partner ID must be unique");
      }
    }
    return errors;
  };

  function setSchema(formData: Contract) {
    setContractType(formData.type || contractType);
    switch (formData.type) {
      case "vod":
        setCurrentSchema(vodSchema);
        break;
      case "ssai":
        setCurrentSchema(ssaiSchema);
        break;
      case "epg":
        setCurrentSchema(epgSchema);
        break;
      case "tvod":
        setCurrentSchema(tvodSchema);
        break;
      case "demand":
        setCurrentSchema(demandSchema);
        break;
      case "distribution":
        setCurrentSchema(distributionSchema);
        break;
      case "distribution-ads":
        setCurrentSchema(distributionSchema);
        break;
      case "distribution-tvod":
        setCurrentSchema(distributionSchema);
        break;
      case "distribution-plex-pass":
        setCurrentSchema(distributionSchema);
        break;
      default:
        break;
    }
  }

  const onFormChange = (formData: Contract) => {
    if (contractToEdit !== null) {
      if (contractToEdit.type !== formData.type) {
        setContractToEdit(formData);
      }
      setSchema(formData);
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress style={{ marginTop: "4em", marginBottom: "4em" }} />
      </div>
    );
  }

  let dialogTitle;
  if (contractToEdit !== null) {
    if (contractToEdit.uid != null) {
      dialogTitle = "Edit record details";
    }
    if (contractToEdit.amendedContractId !== null) {
      dialogTitle = "Add contract amendment";
    }
    if (contractToEdit.amendedContractId === undefined) {
      dialogTitle = "Add new contract";
    }
  }

  return (
    <div className={classes.pageContainer1}>
      <ConfirmDialog
        open={contractToDelete !== null}
        contractToDelete={contractToDelete}
        setContractToDelete={setContractToDelete}
        onDelete={onDelete}
      ></ConfirmDialog>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          {dialogTitle}
        </Grid>
        <Grid item xs={8} />
        <Grid item xs={1} style={{ textAlign: "right" }}>
          <IconButton
            aria-label="close"
            onClick={() => handleFormClose(false)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      {contractToEdit && (
        <Form
          className={classes.rjsf}
          ObjectFieldTemplate={ContractObjectFieldTemplate}
          schema={currentSchema}
          formData={contractToEdit}
          uiSchema={contractUiSchema}
          onSubmit={handleSubmit}
          validate={validate}
          onChange={(e) => onFormChange(e.formData)}
        >
          <Grid container spacing={2} className={classes.formButtons}>
            <Grid item xs={5} />
            <Grid item xs={1}>
              {submissionLoading ? (
                <CircularProgress />
              ) : (
                <Button variant="outlined" color="primary" type="submit">
                  Submit
                </Button>
              )}
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="outlined"
                color="secondary"
                type="button"
                onClick={(formData: Contract) => {
                  formData.changed = false;
                  handleFormClose(true);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={5} />
          </Grid>
        </Form>
      )}
      {/* </DialogContent>*/}
      {/* </Dialog>*/}
      <MUIDataTable
        title={showTitle ? "Contracts" : ""}
        columns={generatedColumns}
        data={allContracts}
        options={{
          selectableRows: "none",
          storageKey: "contractGrid",
          enableNestedDataAccess: ".",
          downloadOptions: {
            filename: `PartnerFacts-Contracts-${dateUtils.fileNameDate()}`,
            filterOptions: {
              useDisplayedColumnsOnly: true,
            },
          },
          customToolbar: () => <AddIconButton handleClick={onAddNew} />,
        }}
      />
      <Snackbar
        open={snack.show}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert severity={snack.severity} onClose={handleSnackClose}>
          {snack.alert}
        </Alert>
      </Snackbar>
    </div>
  );
};
