import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Link,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import { format, parseISO, add, isPast } from "date-fns";
import { useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Alert from "@mui/material/Alert";
import {
  Contract,
  Partner,
  severityString,
  Snack,
  approval,
} from "../../../utils/types";
import { getAll } from "../../../utils/dataAccess";
import { ApprovalItem } from "./ApprovalItem";

const useStyles = makeStyles((theme) => ({
  dataPointContainer: {
    minWidth: "230px",
  },
  pageContainer: {
    marginTop: "30px",
    marginBottom: "30px",
  },
}));

function ApprovalPage({ user }: any) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [contractName, setContractName] = useState(null);
  const [approvals, setApprovals] = useState<any>([{ uid: 50 }]);
  const [loading, setLoading] = useState<boolean | null>(null);
  const [snack, setSnack] = useState<Snack>({
    show: false,
    alert: "Nothing to see here...",
    severity: "success",
  });
  type IdParams = {
    contractId: string;
  };
  const { contractId } = useParams<IdParams>();

  const getApprovals = useCallback(() => {
    const whereClause: string[] = [
      `cid = ${contractId} AND active = true ORDER BY uid`,
    ];
    getAll("approvals", ["*"], whereClause)
      .then((x) => {
        setApprovals(x);
        getContractName();
      })
      .catch((err) => {
        setSnackDetails("Error getting approvals", "error");
        console.error(err);
      });
  }, [contractId]);

  useEffect(() => {
    getApprovals();
  }, []);

  const getContractName = () => {
    getAll("contracts", ["*"], [`uid = ${contractId}`, "active = true"])
      .then((res) => {
        !!res[0].name && setContractName(res[0].name);
        setLoading(false);
      })
      .catch((err) => {
        console.error("GetAll error in Components ApprovalItem Function", err);
      });
  };

  const setSnackDetails = (alert: any, severity: severityString) => {
    setSnack({
      show: true,
      severity,
      alert,
    });
  };
  const renderLoading = () => (
    <>
      <Modal
        disableAutoFocus
        open={!!loading}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            height: "10vh",
            width: "10vh",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "3px",
          }}
        >
          <CircularProgress />
        </Box>
      </Modal>
    </>
  );

  const handleSnackClose = () => {
    setSnack({ show: false, severity: "success", alert: null });
  };

  const DataPoint = ({ name, value }: any) => (
    <div style={{ padding: "4px" }}>
      <Typography variant="subtitle1">{name}</Typography>
      <Typography variant="body2">{value}</Typography>
    </div>
  );

  return (
    <>
      <div className={classes.pageContainer}>
        {loading && renderLoading()}
        {approvals.length > 0 && (
          <Container maxWidth={"lg"}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                {errorMessage && (
                  <Typography variant="h4">Error: {errorMessage}</Typography>
                )}
                {contractName && (
                  <Typography variant="h4">
                    Approvals for {contractName}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                {approvals.map((ob: any, i: number) => (
                  <ApprovalItem
                    setSnackFnc={setSnack}
                    cid={ob.cid}
                    key={ob.uid}
                    uid={ob.uid}
                    dateAdded={ob.dateAdded}
                    dateEdited={ob.dateEdited}
                    email={user.profileObj.email}
                    userteam={ob.team}
                    status={ob.isApproved}
                    comments={ob.comment}
                    name={contractName}
                    getFnc={getApprovals}
                    setLoading={setLoading}
                  />
                ))}
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
      <Snackbar
        open={snack.show}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert severity={snack.severity} onClose={handleSnackClose}>
          {snack.alert}
        </Alert>
      </Snackbar>
    </>
  );
}

ApprovalPage.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ApprovalPage;
