import React from "react";
import { Box, TableCell, TableRow, Typography } from "@mui/material";

interface Props {
  label: string;
  icon: any;
  stat: string;
}

export const StatsTableRow: React.FC<Props> = ({
  label,
  icon,
  stat,
}: Props) => {
  return (
    <>
      <TableRow>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            {icon}
            <Typography>{label}</Typography>
          </Box>
        </TableCell>
        <TableCell>{stat && stat}</TableCell>
      </TableRow>
    </>
  );
};
