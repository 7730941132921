import LookUp from "../../LookUpDropDown";

/**
 * Setup the specific rendering for the fields in this form
 *
 * Custom widget for the Partner Lookup
 */
export const contractUiSchema = {
    "ui:order": ["uid", "pid", "name", "partner_id", "type", "sid", "effectiveDate", "endDate", "dateAdded", "lastEdited", "lastEditedBy", "contractSource", "contractUrl", "terms", "reporting", "notes", "*"],
    uid: {
        "ui:disabled": true,
        "ui:widget": "hidden",
        "ui:description": null
    },
    pid: {
        "ui:widget": LookUp,
        "ui:field": "String",
        "ui:options": {
            table: "partners",
            displayField: "partner"
        },
        "ui:title": "Related Partner",
        //"ui:description": "Partner associated with this contract"
    },
    sid: {
        "ui:widget": LookUp,
        "ui:field": "String",
        "ui:options": {
            table: "contracts",
            displayField: "name",
            filter: ["type = 'ssai'"]
        },
        "ui:title": "Distribution Partner",
        "ui:description": "Partner contract associated with this contract"
    },
    lastEditedBy: {"ui:disabled": true},
    lastEdited: {"ui:disabled": true},
    dateAdded: {"ui:disabled": true},
    notes: {
        items: {
            "ui:widget": "textarea",
            "ui:placeholder": "Any form of comments or notes"
        }
    },
    reporting: {
        "ui:accordion": true,
        revenueReporting: {
            "ui:widget": "textarea",
            "ui:options": {"rows": 2},
            "ui:placeholder": "How are reports delivered?",

        }
    },
    active: {
        "ui:widget": "hidden"
    },
    approvals: {
        "ui:options": {
            "orderable": false,
            "removable": false
        },
        "ui:accordion": false,
        items: {

            userEmail: {"ui:disabled": true},
            dateReviewed: {"ui:disabled": true},
            userTeam: {"ui:disabled": false},
            comments: {"ui:widget": "textarea"}
        }
    }

}