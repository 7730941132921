import {Box, CircularProgress, Modal} from "@mui/material";
import React from "react";

interface Props{
    isLoading: boolean;
}

export const LoadingModal = ({isLoading}: Props) => {
    return (
        <>
            <Modal
                disableAutoFocus
                open={isLoading}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        height: "10vh",
                        width: "10vh",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "3px",
                    }}
                >
                    <CircularProgress />
                </Box>
            </Modal>
        </>
    );
};