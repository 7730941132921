class DateUtils {
  formatDate = (date: Date | string) => {
    return this.formatDateTime(date, false);
  };
  formatDateTime = (
    date: Date | string,
    includeItem = true,
    hour12 = true
  ) => {
    if (!date) {
      return "";
    }
    const dateObject = typeof date === "string" ? new Date(date) : date;

    const timeString = includeItem
      ? ` ${dateObject.toLocaleTimeString([], { hour12: hour12 })}`
      : "";
    return `${dateObject.toLocaleDateString()}${timeString}`;
  };
  fileNameDate = () => {
    const dateString = new Date();
    return `${dateString.getDate()}-${dateString.getMonth()}-${dateString.getFullYear()}`;
  };
}

export default new DateUtils();
