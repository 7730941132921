import LookUp from "../../LookUpDropDown";

/**
 * Setup the specific rendering for the fields in this form
 *
 * Custom widget for the Partner Lookup
 */
export const contactUiSchema = {
    "ui:order": ["uid", "pid", "cid", "*", "active"],
    uid: {
        "ui:disabled": true,
        "ui:widget": "hidden",
        "ui:description": null
    },
    pid: {
        "ui:widget": LookUp,
        "ui:field": "String",
        "ui:options": {
            table: "partners",
            displayField: "partner"
        },
        "ui:title": "Related Partner",
        "ui:description": "Partner associated with this contact"
    },
    cid: {
        "ui:widget": LookUp,
        "ui:field": "String",
        "ui:options": {
            table: "contracts",
            displayField: "name"
        },
        "ui:title": "Related Contract",
        "ui:description": "Contract associated with this contact"
    },
    lastEditedBy: {"ui:disabled": true},
    lastEdited: {"ui:disabled": true},
    dateAdded: {"ui:disabled": true},
    active: {
        "ui:widget": "hidden"
    }


}