import React from 'react';
import PropTypes from 'prop-types';
import {Divider, List, ListItem, ListItemText} from "@mui/material";

const NotesListing = ({notes}) => {
    return (
        <List>
            {(notes || []).map((x, i) => (
                <React.Fragment key={i}>
                    <ListItem key={i}>
                        <ListItemText>{x}</ListItemText>
                    </ListItem>
                    {i !== notes.length - 1 && <Divider varient="inset" component="li"/>}
                </React.Fragment>
            ))}
        </List>
    );
};

NotesListing.propTypes = {
    notes: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default NotesListing;