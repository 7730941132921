export const termsUISchema = {
  "ui:submitButtonOptions": {
    props: {
      disabled: true,
      className: "btn btn-info",
    },
    norender: true,
    submitText: "",
  },
};
