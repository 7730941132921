import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { termsUISchema } from "./termsUISchema";
import { Close } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { MuiForm5 as Form } from "@rjsf/material-ui";
import { lineItem, Terms } from "../../utils/types";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/lab";
import { ExclusivitySelect } from "./ExclusivitySelect";
import { update } from "../../utils/dataAccess";
import { LoadingModal } from "../shared/LoadingModal";
import { IChangeEvent } from "@rjsf/core";
import { ContractObjectFieldTemplate } from "../contract/subComponents/ContractObjectFieldTemplate";
import DialogActions from "@mui/material/DialogActions";
import ContentTierSelect from "./ContentTierSelect";
const contractsSchema = require("../../model/contract/Contract.json");
const termsSchema = contractsSchema.properties.terms;

interface Props {
  setModalClose: () => void;
  lineItem: lineItem;
  getAll: () => void;
}

export const EditLineItemDialog: FC<Props> = (props: Props): JSX.Element => {
  const { setModalClose, lineItem, getAll } = props;
  const useStyle = makeStyles((theme) => ({
    rjsf: {
      margin: 8,
      overflow: "scroll",
    },
    formButtons: {
      textAlign: "center",
      margin: theme.spacing(2),
    },
    form: {
      margin: "20px",
    },
  }));
  const classes = useStyle();

  const [formData, setFormData] = useState<lineItem>();
  const [formTerms, setFormTerms] = useState<Terms>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isCustomValid, setIsCustomValid] = useState<boolean>(false);
  const [isBuiltInValid, setIsBuiltInValid] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  useEffect(() => {
    setFormData(lineItem);
    setFormTerms(lineItem.terms);
  }, []);

  useEffect(() => {
    console.log(formTerms);
    formData && formTerms && setFormData({ ...formData, terms: formTerms });
  }, [formTerms]);

  const handleBooleanOrStringChange = (
    e: boolean | string,
    key: keyof lineItem
  ) => {
    formData && setFormData({ ...formData, [key]: e });
  };
  const validate = (formData: Terms, errors: any) => {
    if (formData.maximumCommission === 0) {
      errors.maximumCommission.addError("Maximum Commissions can't be zero");
    }

    let hasError = false;
    for (const props of Object.keys(errors)) {
      if (errors[props].__errors && errors[props].__errors.length > 0) {
        hasError = true;
      }
    }
    if (hasError) {
      console.log("Form has an error");
      setIsCustomValid(false);
    } else {
      console.log("Form does not have an error");
      setIsCustomValid(true);
    }

    return errors;
  };

  const handleArrayChange = (
    e: React.SyntheticEvent,
    newValue: string[],
    key: keyof lineItem
  ) => {
    formData && setFormData({ ...formData, [key]: newValue });
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleDateChange = (
    newValue: string | null | undefined,
    key: string
  ) => {
    if (!newValue) return;
    formData &&
      setFormData({
        ...formData,
        [key]: new Date(newValue).toISOString().slice(0, 10).replace("T", " "),
      });
  };

  const parseValidation = (
    formData: Partial<lineItem> | undefined,
    terms: Terms
  ) => {
    if (formData === undefined) return;

    const form = formData as lineItem;
    const requiredLineItemFields: (keyof lineItem)[] = [
      "startDate",
      "endDate",
      "name",
      "content",
      "locationsIsExclusive",
    ];
    const requiredTermsFields: (keyof Terms)[] = ["shareType"];

    const requiredLineItemCombinations = [[]];
    const requiredTermsCombinations = [[], []];

    const forbiddenLineItemCombinations = [
      ["", ""],
      ["", "", "", ""],
    ];

    const missingRequiredLineItemFields = [];
    const missingRequiredTermsFields = [];
    const missingRequiredLineItemCombinations = [];
    const missingRequiredTermsCombinations = [];

    for (let i = 0; i < requiredLineItemFields.length; i++) {
      !formData.hasOwnProperty(requiredLineItemFields[i].toString()) &&
        missingRequiredLineItemFields.push(
          "missing " + requiredLineItemFields[i]
        );
    }
    for (let i = 0; i < requiredTermsFields.length; i++) {
      !terms.hasOwnProperty(requiredTermsFields[i].toString()) &&
        missingRequiredTermsFields.push("missing " + requiredTermsFields[i]);
    }

    for (let i = 0; i < requiredLineItemCombinations.length; i++) {
      !requiredLineItemCombinations[i].every((key) =>
        formData.hasOwnProperty(key)
      ) &&
        missingRequiredLineItemCombinations.push(
          requiredLineItemCombinations[i].join(" and ") +
            " must be present together"
        );
    }

    if (
      missingRequiredLineItemFields.length ||
      missingRequiredLineItemCombinations.length
    ) {
      return {
        isValidated: false,
        missingRequiredLineItemFields: missingRequiredLineItemFields,
        missingCombinations: missingRequiredLineItemCombinations,
      };
    }

    return { isValidated: true };
  };

  const handleSubmit = () => {
    setLoading(true);
    // Check for required values
    update("line_items", formData)
      .then((res) => {
        setLoading(false);
        setModalClose();
        getAll();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTermsChange = (e: IChangeEvent<any>) => {
    setFormTerms(e.formData);
  };

  const handleTransformErrors = (errors: any) => {
    setTimeout(() => setIsBuiltInValid(errors.length === 0), 0);

    return errors;
  };

  return (
    <>
      <LoadingModal isLoading={loading} />
      <Dialog
        open={isOpen}
        fullWidth={true}
        onClose={handleDialogClose}
        maxWidth="xl"
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <DialogActions>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Typography variant="h6">Edit Line Item</Typography>
                  <IconButton onClick={handleDialogClose}>
                    <Close />
                  </IconButton>
                </Stack>
              </DialogActions>
              <Divider />
            </Grid>
            <Stack className={classes.form} spacing={1}>
              <h1>Content</h1>
              <ExclusivitySelect
                title={"Content Scope"}
                onExclusivityChange={(isExclusive) => {
                  handleBooleanOrStringChange(
                    isExclusive,
                    "contentIsExclusive"
                  );
                }}
                currentStatus={formData?.contentIsExclusive}
              />
              <Autocomplete
                multiple
                id="id"
                freeSolo
                options={formData?.content.map((option) => option) || [""]}
                defaultValue={formData?.content.map((option) => option)}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} label={"Content"} />
                )}
                onChange={(e, newValue) => {
                  handleArrayChange(e, newValue, "content");
                }}
              />
              <ContentTierSelect
                onContentTierChange={(contentTier: string) => {
                  handleBooleanOrStringChange(contentTier, "contentTiers");
                }}
              />

              <h1>Devices</h1>
              <ExclusivitySelect
                title={"Device Exclusivity"}
                onExclusivityChange={(isExclusive) => {
                  handleBooleanOrStringChange(
                    isExclusive,
                    "devicesIsExclusive"
                  );
                }}
                currentStatus={formData?.devicesIsExclusive}
              />
              <Autocomplete
                multiple
                id="id"
                freeSolo
                options={formData?.devices.map((option) => option) || [""]}
                defaultValue={formData?.devices.map((option) => option)}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} label={"Devices"} />
                )}
                onChange={(e, newValue) => {
                  handleArrayChange(e, newValue, "devices");
                }}
              />
              <Box>
                <h1>Locations</h1>
              </Box>
              <ExclusivitySelect
                title={"Location Exclusivity"}
                onExclusivityChange={(isExclusive) => {
                  handleBooleanOrStringChange(
                    isExclusive,
                    "locationsIsExclusive"
                  );
                }}
                currentStatus={formData?.locationsIsExclusive}
              />
              <Autocomplete
                multiple
                id="id"
                freeSolo
                options={formData?.locations.map((option) => option) || [""]}
                defaultValue={formData?.locations.map((option) => option)}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} label={"Locations"} />
                )}
                onChange={(e, newValue) => {
                  handleArrayChange(e, newValue, "locations");
                }}
              />
              <Box>
                <h1>Start/End Date</h1>
                <Stack direction={"row"} spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Start Date"
                      onChange={(e) => {
                        handleDateChange(e, "startDate");
                      }}
                      value={formData?.startDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      onChange={(e) => {
                        handleDateChange(e, "endDate");
                      }}
                      value={formData?.endDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Stack>
              </Box>
              <h1>Terms</h1>
              <Form
                ObjectFieldTemplate={ContractObjectFieldTemplate}
                liveValidate
                formData={formTerms}
                schema={termsSchema}
                uiSchema={termsUISchema}
                onChange={handleTermsChange}
                validate={validate}
                transformErrors={handleTransformErrors}
              />
            </Stack>
            <Grid item xs={12}>
              <Divider />
              <DialogActions>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="right"
                >
                  <Button
                    disabled={!isCustomValid || !isBuiltInValid}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <Button onClick={handleDialogClose}>Cancel</Button>
                </Stack>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
