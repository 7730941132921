import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
const ConfirmDialog = (props: { open: any; contractToDelete: number | null; setContractToDelete: any; onDelete: any }) => {
  const { open, setContractToDelete, contractToDelete, onDelete } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setContractToDelete(null)}
    >
      <DialogTitle >Delete record</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this record?
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setContractToDelete(null)}
          color="secondary"
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onDelete(contractToDelete);
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;