import React from "react";
import pj from "../../package.json";

const Footer = () => (
  <div className="Footer" style={{ textAlign: "center" }}>
    <p>
      &copy; {`Plex Partner Facts ${new Date().getFullYear()}`} - Version:{" "}
      {pj.version}
    </p>
  </div>
);

export default Footer;
