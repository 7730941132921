import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  List,
  ListItem,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { getAdminReport } from "../utils/dataAccess";
import { ToggleButton } from "@mui/lab";
import { Switch } from "@mui/material";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { contractColumnsFactory } from "../model/contract/ContractColumnsFactory";
import { number, string } from "prop-types";
import guaranteePage from "../components/guarantees/GuaranteePage";

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: "30px",
    paddingBottom: "50px",
  },
  title: {
    paddingTop: "20px",
  },
}));

export default function AdminPage() {
  const classes = useStyles();
  const [contracts, setContracts] = useState<any[] | null>(null);
  const [allowDelete, setAllowDelete] = useState<boolean | null>(null);
  const [auditColumns, setAuditColumns] = useState<{}[]>([]);
  let allowDeleteLocalStorage: string | null;

  const getReport = useCallback(() => {
    getAdminReport()
      .then((x) => {
        setContracts(x);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    getReport();
    allowDeleteLocalStorage = JSON.parse(
      localStorage.getItem("allowDelete") ?? "false"
    );
    setAllowDelete(JSON.parse(localStorage.getItem("allowDelete") ?? "false"));
  }, []);

  useEffect(() => {
    createAdminAuditColumns(contracts);
  }, [contracts]);

  const toggleDelete = () => {
    setAllowDelete(!allowDelete);
    localStorage.setItem("allowDelete", !allowDelete ? "true" : "false");
  };

  interface dictionary {
    [key: string]: any;
  }
  interface shareTypeOb {
    shareType: string;
    inventoryShare: number;
    revenueShare: number;
  }

  const createAdminAuditColumns = (rows: any) => {
    const adminAuditColumns: {}[] = [];

    if (rows == null || rows.length <= 0) return;

    for (const object of rows) {
      if (object.contractData && object.errors) {
        const pushObj: dictionary = {};
        pushObj.cid = object.contractData.uid;
        pushObj.type = object.contractData.type;
        pushObj.incorrectFields = object.errors;
        pushObj.lastEdited = object.contractData.lastEditedBy;
        pushObj.actions = "";

        adminAuditColumns.push(pushObj);
      }
    }

    setAuditColumns(adminAuditColumns);
  };

  return (
    <div>
      <Container maxWidth={"xl"} className={classes.section}>
        <Typography className={classes.title} variant={"h3"}>
          Admin
        </Typography>
        <Box className={classes.section}>
          <Typography variant={"h6"}>Allow Deletion of Records</Typography>
          {allowDelete !== null && (
            <Switch checked={allowDelete} onChange={toggleDelete} />
          )}
        </Box>
        <Box className={classes.section}>
          {contracts ? (
            <>
              <MUIDataTable
                columns={[
                  {
                    name: "cid",
                    label: "Contract ID",
                    options: {
                      customBodyRender: (value, tableMeta, updateValue) => (
                        <Link to={`/contracts/${value}`}>{value}</Link>
                      ),
                    },
                  },
                  {
                    name: "type",
                    label: "Type",
                  },
                  { name: "incorrectFields", label: "Incorrect Fields" },
                  { name: "lastEdited", label: "Last Edited By" },
                  { name: "actions", label: "Options" },
                ]}
                data={auditColumns}
                title={"Title"}
              />
            </>
          ) : (
            <Typography variant={"body1"}>
              There are no partners missing necessary information.
            </Typography>
          )}
        </Box>
      </Container>
    </div>
  );
}
