/**
 * Utility function to create the contractColumns to be used in the table
 *
 *
 */

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import dateUtils from "../../utils/dateUtils";
import { MUIDataTableColumn } from "mui-datatables";

/**
 * Defintion of the table headers
 *
 * @todo #6 Will want to dynamically create this based on schema or selected contractColumns
 *
 */
export const contactColumnsFactory = (
  onEdit: (contactId: number) => void,
  embed: any
): MUIDataTableColumn[] =>
  [
    {
      name: "uid",
      label: "uid",
    },
    {
      name: "pid",
      label: "pid",
    },
    {
      name: "cid",
      label: "cid",
    },
    {
      name: "name",
      label: "Contact Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Contact email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastEdited",
      label: "Last Edit",
      options: {
        filter: true,
        sort: true,
        customBodyRender: dateUtils.formatDate,
      },
    },
    {
      name: "lastEditedBy",
      label: "Last Editor",
      options: {
        filter: true,
        sort: true,
      },
    },
    embed
      ? null
      : {
          name: "actions",
          label: "Actions",
          options: {
            empty: true,
            customBodyRender: (
              value: any,
              tableMeta: any,
              updateValue: any
            ) => (
              <IconButton size="large">
                <EditIcon onClick={() => onEdit(tableMeta.rowData[0])} />
              </IconButton>
            ),
          },
        },
  ].filter((x) => x) as MUIDataTableColumn[];
