/**
 * dataAccess.js
 * module to isolate all interaction with BigQuery
 *
 *
 */
import { accessTokenPromise } from "./authorization";
import { Contract } from "./types";
import { ApprovalListing } from "../model/approval/approval";
import { cleanup } from "@testing-library/react";
import { settings } from "../settings";

const axios = require("axios");

const baseURL = settings.REACT_APP_URL || "http://localhost:3100";

/**
 * Setup User Specific BigQuery client
 * Called from Login
 */

const clientPromise = accessTokenPromise.then((x) =>
  axios.create({
    headers: { Authorization: `Bearer ${x}` },
    baseURL: baseURL,
  })
);

export async function getAdminReport() {
  // Build up URL to hit server with
  const reqUrl = `/admin/audit-events`;
  const client = await clientPromise;
  return client
    .get(reqUrl)
    .then(({ data }: any) => {
      return data;
    })
    .catch((err: any) => {
      console.error(err);
      throw handleError("Get admin All Error", err);
    });
}

/**
 * Get 'all' records from a given table, based on the list of fields and where clause
 *
 * @param {string} table name of the table to query the data
 * @param {[string]} fields array of strings representing the columns expected - default = *
 * @param {[string]} where array of conditions to add to the WHERE clause
 * @param {string} other string of SQL to add after the WHERE clause - often a LIMIT or ORDER BY
 */
export async function getAll(
  table: string,
  fields = ["*"],
  where: string[] = [],
  other = ""
) {
  // Build up URL to hit server with
  const reqUrl = `/data/${table}?fields=${fields}&where=${where}&other=${other}`;
  const client = await clientPromise;
  return client
    .get(reqUrl)
    .then(({ data }: any) => {
      return data;
    })
    .catch((err: any) => {
      console.error(err);
      throw handleError("Get All Error", err);
    });
}

/**
 * Get all approvals associated with a business division alongside their corresponding contracts
 */
export async function getBizApprovals(
  division: string | null,
  searchTerm: string | null,
  getall: boolean | null
): Promise<Array<ApprovalListing>> {
  const queryParams = searchTerm ? `searchTerm=${searchTerm}` : "";
  const getAllCheck = getall ? `&getAll=${getall}` : "";
  const reqUrl = `data/bizapproval/division?division=${division}&${queryParams}${getAllCheck}`;
  const client = await clientPromise;
  return client.get(reqUrl).then(({ data }: { data: Array<any> }) => {
    return data.map(ApprovalListing.mapFromServer);
  });
}
/**
 * Get the record[s] beloning to a specific partner, and optionally a specific contract
 *
 *
 * @param {string} table    name of the table to query the data
 * @param {int} uid         Unique ID of the record
 * @param {[string]} fields
 * @param {[string]} where  array of conditions to add to the WHERE clause
 * @param {string} other    string to be added after the WHERE clause
 */
export async function getById(
  table: any,
  uid: any,
  fields = ["*"],
  where = [],
  other = ""
) {
  if (!uid) {
    return handleError("getById: Missing UID");
  }
  const whereString = where
    .reduce((c, x) => `${c}&where=${encodeURIComponent(x)}`, "")
    .substring(1);
  const reqUrl = `/data/${table}/${uid}?${fields}&${whereString}&${other}`;
  const client = await clientPromise;
  return client
    .get(reqUrl)
    .then(({ data }: any) => {
      return data;
    })
    .catch((err: any) => {
      throw handleError("Get By ID Error", err);
    });
}

/**
 * Returns an array of Key Value pairs for use in displaying names for UIDs
 *
 * @todo Implement Cache at this level - Cache doesn't work, may need help
 *
 * @param {String} table Name of the table to pull from
 * @param {String} displayField Which field to display - uid will be used as the key
 */
export async function getLookUp(table: any, displayField: any, where = []) {
  if (!table || !displayField) {
    throw handleError("Get Lookup: Missing Parameters");
  }

  const reqUrl = `/data/${table}/lookup?displayField=${displayField}&where=${where}`;

  const client = await clientPromise;
  return client
    .get(reqUrl)
    .then(({ data }: any) => {
      return data;
    })
    .catch((err: any) => {
      throw handleError("Get Lookup Error", err);
    });
}

/**
 * Updates an existing record in BQ - following the "journaling" strategy
 *
 *
 *  - This version will pull the existing record before updating, should we just pass it as a param?
 *
 * @param {string} table    name of the table to query the data
 * @param {Object} record   Object representing the record to be updated
 */
export async function update(table: any, record: any, oldRecord: any = null) {
  // check for uid
  if (!record.uid) throw handleError("Update: Missing UID");
  record.active = true;
  const client = await clientPromise;
  return client
    .put(`/data/${table}`, record)
    .then((resp: { data: any }) => {
      return resp.data;
    })
    .catch((err: any) => {
      const msg = handleError("Update Error", err);
      if (msg.retry) {
        // Do retry stuff
      }
      throw msg;
    });
}

// Call REST function to insert record[s]
// If 503 error returned, send snack to notify, and continue retrying
export async function insert(table: any, record: any) {
  if (!table || !record) throw handleError("Insert: Missing Parameters");
  const client = await clientPromise;
  return client
    .post(`/data/${table}`, record)
    .then(({ data }: any) => {
      return data;
    })
    .catch((err: any) => {
      const msg = handleError("Insert Error", err);
      if (msg.retry) {
        // Do Retry
      }
      throw msg;
    });
}

export async function archive(table: string, record: Contract) {
  if (!table || !record) return handleError("Nothing to archive");

  const client = await clientPromise;
  return client
    .delete(`/data/${table}/${record.uid}`)
    .then(({ data }: any) => {
      return data;
    })
    .catch((err: any) => {
      const msg = handleError("Archive Error", err);
      if (msg.retry) {
        // maybe do retries here?
        // timer that just polls status on the server - display snack when finished
      }
      throw msg;
    });
}

export async function updateApproval(table: string, record: object) {
  const client = await clientPromise;
  return client
    .put(`/data/${table}`, record)
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err;
    });
}

export async function getContractAndLineItems(uid: number) {
  const client = await clientPromise;
  return client.get(`/contractLineItems/${uid}`);
}

//////////////////////// UTILTY FUNCTIONS ///////////////////////////////
/**
 * Handle Error Utility for all of Data Access - Used to wrap errors and throw appropriate exception
 * @param {String} msg
 * @param {error} err
 */
function handleError(msg: string, err: any = null) {
  //err = err || new Error(msg);
  if (err === null) {
    console.error(msg);
    //Promise.reject(msg);
    return { snack: true, severity: "error", alert: msg };
  }

  console.error(msg, err);
  if (err?.response) {
    if (err?.response.status === 503) {
      console.error("Buffer Error - Retrying");
      console.log(err.response);
      //err?.retry = true;
      // Need to setup the snack correctly to show that the record is in queue
      return err;
      // Do something about Polling status
      // timer that just polls status on the server - display snack when finished?
    }
    return err?.response.data;
  }
  return {
    snack: true,
    severity: "error",
    alert: err.message,
  };
}
