/**
 * Extracting the Grid, Dialog, Etc... UI as a functional component for re-use
 *
 * @author Todd Hay
 *
 * @todo Clean-up toolbar and implement search & flitering
 * @todo How to deal with SnackBar functionality? -- use Promises for functions that set Snacks.
 * @todo Pull up PropTypes
 *
 */

// Libraries
import React, { useCallback, useEffect, useState } from "react";

// https://github.com/mui-org/material-ui
import { CircularProgress, Snackbar } from "@mui/material";

// https://github.com/gregnb/mui-datatables
import MUIDataTable from "mui-datatables";

// Local
import { getAll } from "../../utils/dataAccess";
import AddIconButton from "../shared/AddIconButton";
import { partnerColumnsFactory } from "../../model/partner/PartnerColumnsFactory";
import Alert from "@mui/material/Alert";
import PartnerFormDialog from "./PartnerFormDialog";
import { Partner, Snack, UserType } from "../../utils/types";
import dateUtils from "../../utils/dateUtils";

/**
 * Primary container for the List of records
 *
 * Depends on dataAccess.ts for fetching and updating data from DB
 *
 * Hosts the edit UI in FormComponent.tsx
 *
 * For passed in functions -> check for typeof === 'function' then call, otherwise use default implementation.
 *
 */

export default function PartnerGrid(props: { user: UserType }) {
  const [allPartners, setAllPartners] = useState<Partner[]>([]);
  const [partnerToEdit, setPartnerToEdit] = useState<
    Partner | null | undefined
  >(null);
  const [loading, setLoading] = useState(true);
  const [snack, setSnack] = useState<Snack>({
    show: false,
    alert: "Nothing to see here...",
    severity: "success",
  });
  const { user } = props;
  const initialColumns = [
    "partner",
    "type",
    "owner",
    "lastEdited",
    "lastEditedBy",
    "dateAdded",
    "actions",
  ];

  const getPartners = useCallback(() => {
    setLoading(true);
    getAll("partners", ["*"], ["active = TRUE"])
      .then((x) => {
        setAllPartners(x);
        setLoading(false);
      })
      .catch((err) => {
        setSnack({
          show: true,
          alert: "Error getting partners.",
          severity: "error",
        });
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getPartners();
  }, [getPartners]);

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress style={{ marginTop: "4em", marginBottom: "4em" }} />
      </div>
    );
  }

  const onAddNew = () => {
    setPartnerToEdit({ uid: 0 });
  };

  const onEdit = (partnerId: string) => {
    const p = allPartners.filter((y) => y.uid === parseInt(partnerId));
    setPartnerToEdit(p[0]);
  };

  const handleSnackClose = () => {
    setSnack({ ...snack, show: false });
  };

  const handleFormClose = (formUpdatedOrSubmitted: boolean) => {
    if (formUpdatedOrSubmitted) {
      getPartners();
    }
    setPartnerToEdit(null);
  };

  const localColumns = localStorage.getItem("partnerGrid");
  let displayedColumns;
  if (localColumns != null) {
    const localColumnsObject: { columns: any } = JSON.parse(localColumns);
    displayedColumns = localColumnsObject.columns.filter(
      (obj: any) => obj.display == "true"
    );
  }

  const generatedColumns = partnerColumnsFactory(onEdit).map((x) => ({
    ...x,
    options: {
      ...x.options,
      display:
        localColumns && displayedColumns.length > 0
          ? undefined
          : initialColumns.indexOf(x.name) >= 0,
    },
  }));

  return (
    <div>
      <MUIDataTable
        title="Partners"
        columns={generatedColumns}
        data={allPartners}
        options={{
          selectableRows: "none",
          storageKey: "partnerGrid",
          enableNestedDataAccess: ".",
          downloadOptions: {
            filename: `PartnerFacts-Partners-${dateUtils.fileNameDate()}`,
            filterOptions: {
              useDisplayedColumnsOnly: true,
            },
          },
          customToolbar: () => <AddIconButton handleClick={onAddNew} />,
        }}
      />
      <Snackbar
        open={snack.show}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert severity={snack.severity} onClose={handleSnackClose}>
          {snack.alert}
        </Alert>
      </Snackbar>
      {partnerToEdit && (
        <PartnerFormDialog
          user={user}
          allPartners={allPartners}
          partnerId={partnerToEdit.uid}
          show={Boolean(partnerToEdit)}
          setSnack={setSnack}
          onClose={handleFormClose}
        />
      )}
    </div>
  );
}
