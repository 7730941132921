export const formFieldSizes: any = {
    String: 4,
    array: 12,
    boolean: 2,
    default: 4,
    integer: 2,
    number: 2,
    object: 12,
    string: 4,
};
