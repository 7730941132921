import React from "react";
import { TextField } from "@mui/material";

type ContentTierSelectProps = {
  title?: string;
  onContentTierChange: (contentTier: string) => void;
};

export default function ContentTierSelect(props: ContentTierSelectProps) {
  const { title = "Content Tier", onContentTierChange } = props;
  return (
    <TextField
      fullWidth
      label={title}
      onChange={(event) => {
        onContentTierChange(event.target.value);
      }}
    />
  );
}
