import React, {FC} from "react";

import {Accordion, AccordionDetails, AccordionSummary, Grid} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {formFieldSizes} from "../../../model/shared/formFieldSizes";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10
    },

}));
 interface Props {
     DescriptionField: any,
     TitleField: any,
     description: any,
     title: any,
     properties: any,
     required:boolean,
     formData:any ,
     uiSchema: any,
     idSchema: any,
 }

 type OPFProps = {properties: any, classes: any, isInventoryShareType: boolean, isRevenueShareType: boolean, isFixedShareType: boolean};


export const ObjectPropertyFields: FC<OPFProps> = ({properties, classes, isFixedShareType, isInventoryShareType, isRevenueShareType}) =>
    <Grid container={true} spacing={2} className={classes.root}>
    {properties.map((element: any, index: string | number | null | undefined) => {
        const revenueOnlyElements = ["minimumGuarantee","minimumGuaranteeRecoverability"]
        let xs = element.content.props.uiSchema["ui:field"] ?? element.content.props.schema.type;
        xs = Array.isArray(xs) ? xs[0] : xs;
        //console.log(xs, element.content);
        if ((!isInventoryShareType && element.name === "inventoryShare") ||
            (!isRevenueShareType && element.name === "revenueShare")) {
          // if(element.content.props?.formData) element.content.props.formData = 9001;
            return false;
        }
        if ((isRevenueShareType && (element.name === "licenseFee"))) {
            return false;
        }
        if((!isRevenueShareType && revenueOnlyElements.includes(element.name))){
            return false
        }


        if (element.content.props.uiSchema["ui:widget"] !== "hidden" &&
            !element.hidden)
            return (
                <Grid
                    item
                    xs={(formFieldSizes[xs]) ? formFieldSizes[xs] : formFieldSizes['default']}
                    key={index}
                    style={{marginBottom: '10px'}}
                >
                    {element.content}
                </Grid>
            );
        return false;
    }).filter((x: any) => x)}
</Grid>;



export const ContractObjectFieldTemplate: FC<Props> = (props) => {
    const classes = useStyles();
   const {
       DescriptionField,
       TitleField,
       description,
       title,
       properties,
       required,
       formData,
       uiSchema,
       idSchema,
    } = props

    /*
    The schema doesn't seem to supported nested objects for its dependencies
    section so we are handling some field showing and hiding here
     */
    const isRevenueShareType = (formData?.shareType || '').indexOf("revenue") >= 0;
    const isInventoryShareType = (formData?.shareType || '').indexOf("inventory") >= 0;
    const isFixedShareType = (formData?.shareType || '').indexOf("fixed") >= 0;



    const useAccordion = uiSchema['ui:accordion'] || false;
    const fieldProps = {classes, isRevenueShareType, isFixedShareType, isInventoryShareType, properties};
    return useAccordion ?
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                {(uiSchema['ui:title'] || title) && (
                    <TitleField
                        id={`${idSchema.$id}-title`}
                        title={title}
                        required={required}
                    />
                )}
                {
                    description && (
                        <DescriptionField
                            id={`${idSchema.$id}-description`}
                            description={description}
                        />
                    )
                }
            </AccordionSummary>
            <AccordionDetails>
                <ObjectPropertyFields classes={classes} isFixedShareType={isFixedShareType} isRevenueShareType={isRevenueShareType} isInventoryShareType={isInventoryShareType} properties={properties}/>
            </AccordionDetails>
        </Accordion> : <>
            {(uiSchema['ui:title'] || title) && (
                <TitleField
                    id={`${idSchema.$id}-title`}
                    title={title}
                    required={required}
                />
            )}
            {
                description && (
                    <DescriptionField
                        id={`${idSchema.$id}-description`}
                        description={description}
                    />
                )
            }
            <ObjectPropertyFields {...fieldProps}/>
        </>;
};