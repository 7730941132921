import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Contract, lineItem as LineItemType } from "../../utils/types";
import { FormComponent } from "../FormComponent";
import { archive, insert, update } from "../../utils/dataAccess";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { FormProps, FormValidation } from "@rjsf/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useDialogsState from "../../utils/useDialogsState";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import AddIconButton from "../shared/AddIconButton";

const newTvodLineItemSchema: FormProps<LineItemType>["schema"] = {
  type: "object",
  title: "Line Item",
  properties: {
    contentTiers: {
      type: "string",
      title: "Content Tier",
    },
    terms: {
      type: "object",
      title: "Terms",
      properties: {
        revenueShare: {
          title: "Rev Share",
          description:
            "Partner Revenue share (%). How much we send to partner.",
          type: "number",
        },
      },
      required: ["revenueShare"],
    },
  },
  required: ["contentTiers", "terms"],
};

const newTvodLineItemUiSchemaFields: FormProps<LineItemType>["uiSchema"] = {};

type TvodLineItemType = {
  active: boolean;
  uid: number | undefined;
  cid: number;
  pid: number;
  contentTiers: string | undefined;
  terms: {
    revenueShare: number | undefined;
  };
};

function isTvodLineItemType(
  lineItem: LineItemType | TvodLineItemType
): lineItem is TvodLineItemType {
  return typeof lineItem.cid === "number";
}

function EditLineItemDialog({
  lineItem,
  lineItems,
  open,
  onClose,
  onConfirm,
}: {
  lineItem: TvodLineItemType | undefined;
  lineItems: TvodLineItemType[] | undefined;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const [formData, setFormData] = useState<TvodLineItemType | undefined>(
    lineItem
  );

  useEffect(() => {
    setFormData(lineItem);
  }, [lineItem]);

  const isNew = useMemo(() => {
    return lineItem?.uid === undefined;
  }, [lineItem?.uid]);

  const handleSubmit = useCallback(
    async ({
      cancel,
      formData: nextFormData,
    }: {
      cancel: boolean;
      formData: TvodLineItemType;
    }) => {
      if (cancel) {
        onClose();
        return;
      }

      if (isNew) {
        await insert("line_items", nextFormData);
      } else {
        await update("line_items", nextFormData);
      }
      onConfirm();
    },
    [isNew, onClose, onConfirm]
  );

  const handleValidate = useCallback(
    (formData: TvodLineItemType, errors: FormValidation) => {
      const duplicateContentTierLineItem = lineItems?.find(
        (existingLineItem) => {
          return (
            existingLineItem !== lineItem &&
            existingLineItem.contentTiers === formData.contentTiers
          );
        }
      );

      if (duplicateContentTierLineItem) {
        errors.contentTiers.addError(
          `A line item for content tier ${formData.contentTiers} already exists`
        );
      }

      return errors;
    },
    [lineItem, lineItems]
  );

  return lineItem ? (
    <Dialog open={open} fullScreen={true} onClose={onClose}>
      <DialogTitle>
        {isNew ? "New TVOD Line Item" : "Edit TVOD Line Item"}
      </DialogTitle>
      <DialogContent>
        <FormComponent
          schema={newTvodLineItemSchema}
          uiSchemaFields={newTvodLineItemUiSchemaFields}
          formData={formData}
          validate={handleValidate}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  ) : null;
}

export default function TvodLineItemsList({
  contract,
  onRefresh,
}: {
  contract: Contract;
  onRefresh: () => void;
}) {
  const newLineItem = useMemo(() => {
    return typeof contract.uid === "number" && typeof contract.pid === "number"
      ? {
          active: true,
          uid: undefined,
          cid: contract.uid,
          pid: contract.pid,
          contentTiers: undefined,
          terms: {
            revenueShare: undefined,
          },
        }
      : undefined;
  }, [contract.pid, contract.uid]);

  const tvodLineItems = useMemo(() => {
    if (!contract.lineItems) {
      return;
    }
    return contract.lineItems.filter(isTvodLineItemType);
  }, [contract.lineItems]) as TvodLineItemType[] | undefined;

  const [lineItemToEdit, setLineItemToEdit] = useState<TvodLineItemType>();

  const handleDeleteLineItem = useCallback(async () => {
    if (!lineItemToEdit) {
      return;
    }

    await archive("line_items", lineItemToEdit);
    onRefresh();
  }, [lineItemToEdit, onRefresh]);

  const handleUpdateLineItem = useCallback(() => {
    onRefresh();
  }, [onRefresh]);

  const {
    deleteLineItem: {
      isOpen: isDeleteLineItemDialogOpen,
      onOpen: onDeleteLineItemDialogOpen,
      onClose: onDeleteLineItemDialogClose,
      onConfirm: onDeleteLineItemDialogConfirm,
    },
    editLineItem: {
      isOpen: isEditLineItemDialogOpen,
      onOpen: onEditLineItemDialogOpen,
      onClose: onEditLineItemDialogClose,
      onConfirm: onEditLineItemDialogConfirm,
    },
  } = useDialogsState({
    deleteLineItem: handleDeleteLineItem,
    editLineItem: handleUpdateLineItem,
  });

  const handleNewClick = useCallback(() => {
    setLineItemToEdit(newLineItem);
    onEditLineItemDialogOpen();
  }, [newLineItem, onEditLineItemDialogOpen]);

  const handleEditClick = useCallback(
    (lineItem: TvodLineItemType | undefined) => {
      setLineItemToEdit(lineItem);
      onEditLineItemDialogOpen();
    },
    [onEditLineItemDialogOpen]
  );

  const handleDeleteClick = useCallback(
    (lineItem: TvodLineItemType | undefined) => {
      setLineItemToEdit(lineItem);
      onDeleteLineItemDialogOpen();
    },
    [onDeleteLineItemDialogOpen]
  );

  const columns = useMemo(() => {
    return [
      {
        name: "uid",
        label: "uid",
      },
      {
        name: "contentTiers",
        label: "Content Tier",
        options: { filter: true, sort: true },
      },
      {
        name: "terms.revenueShare",
        label: "Revenue Share",
        options: {
          sort: true,
          customBodyRender: (value: any) => {
            return `${value}%`;
          },
        },
      },
      {
        name: "Actions",
        label: "Actions",
        options: {
          customBodyRender: (
            value: unknown,
            tableMeta: {
              currentTableData: {
                rowIndex: number;
                index?: number;
              }[];
              rowIndex: number;
            }
          ) => {
            return (
              <>
                <IconButton
                  onClick={() => {
                    const rowIndex =
                      tableMeta.currentTableData[tableMeta.rowIndex]?.index;

                    handleEditClick(
                      rowIndex !== undefined
                        ? tvodLineItems?.[rowIndex]
                        : undefined
                    );
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    const rowIndex =
                      tableMeta.currentTableData[tableMeta.rowIndex]?.index;

                    handleDeleteClick(
                      rowIndex !== undefined
                        ? tvodLineItems?.[rowIndex]
                        : undefined
                    );
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </>
            );
          },
          download: false,
        },
      },
    ];
  }, [handleDeleteClick, handleEditClick, tvodLineItems]);

  const tableOptions: MUIDataTableOptions = useMemo(() => {
    return {
      enableNestedDataAccess: ".",
      customToolbar: () => <AddIconButton handleClick={handleNewClick} />,
      rowsPerPage: 100,
      selectableRows: "none",
    };
  }, [handleNewClick]);

  return (
    <>
      <Dialog
        open={isDeleteLineItemDialogOpen}
        onClose={onDeleteLineItemDialogClose}
      >
        <DialogTitle>Delete Line Item</DialogTitle>
        <DialogContent>
          Delete the line item {lineItemToEdit?.contentTiers}?
        </DialogContent>
        <DialogActions>
          <Button onClick={onDeleteLineItemDialogConfirm}>Delete</Button>
          <Button onClick={onDeleteLineItemDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <EditLineItemDialog
        lineItem={lineItemToEdit}
        lineItems={tvodLineItems}
        open={isEditLineItemDialogOpen}
        onClose={onEditLineItemDialogClose}
        onConfirm={onEditLineItemDialogConfirm}
      />
      {tvodLineItems ? (
        <MUIDataTable
          title="TVOD Line Items"
          columns={columns}
          options={tableOptions}
          data={tvodLineItems}
        />
      ) : null}
    </>
  );
}
