/**
 * Utility function to create the contractColumns to be used in the table
 *
 *
 */

import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import dateUtils from "../../utils/dateUtils";
import { MUIDataTableColumn } from "mui-datatables";

/**
 * Defintion of the table headers
 *
 * @todo #6 Will want to dynamically create this based on schema or selected contractColumns
 *
 */
export const guaranteeColumnsFactory = (
  onEdit: (guaranteeId: number) => void,
  embed: any
): MUIDataTableColumn[] =>
  [
    {
      name: "uid",
      label: "uid",
    },
    {
      name: "pid",
      label: "pid",
    },
    {
      name: "cid",
      label: "cid",
    },
    {
      name: "contentType",
      label: "Content Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "content",
      label: "Content Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "locationType",
      label: "Location Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: dateUtils.formatDate,
      },
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: dateUtils.formatDate,
      },
    },
    {
      name: "additionalCids",
      label: "Additional Contract Ids",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any[], tableMeta: any) => (
          <div>
            {value.map((x: any, index: number) => (
              <Link key={index} href={`/contracts/${x}`} underline={"hover"}>
                <Chip label={x} variant="outlined" />
              </Link>
            ))}
          </div>
        ),
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        empty: true,
        customBodyRender: (value: any[], tableMeta: any) => (
          <IconButton size="large">
            <EditIcon onClick={() => onEdit(tableMeta.rowData[0])} />
          </IconButton>
        ),
      },
    },
  ].filter((x) => x) as MUIDataTableColumn[];
