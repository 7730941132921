/**
 * Component for editing full records.
 *
 * Expects model specific data to be passed in as props - most likey from GridComponent, though not required
 *
 * @todo Cancel not working correctly...
 *
 * @author Todd Hay
 *
 */

import React, { FC } from "react";
import PropTypes from "prop-types";
import { MuiForm5 as Form } from "@rjsf/material-ui";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Grid } from "@mui/material";
import { ObjectFieldTemplateProps } from "@rjsf/core";

// Local Libraries

// Sizes to use in form
const sizes: any = {
  object: 12,
  array: 12,
  boolean: 2,
  integer: 2,
  number: 2,
  string: 4,
  String: 4,
  default: 4,
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: 10,
    },

    rjsf: {
      margin: 8,
    },

    formButtons: {
      textAlign: "center",
      margin: theme.spacing(2),
    },
  };
});

/**
 * Called by rjsf to allow me to customize the layout
 * @param {*} param0
 *
 * @todo Skip field if ui:widget = hidden
 */

interface OFProps {
  DescriptionField: any;
  description: string;
  TitleField: any;
  title: string;
  properties: any;
  required: boolean;
  uiSchema: any;
  idSchema: any;
}

const ObjectFieldTemplate: FC<OFProps> = (props) => {
  const classes = useStyles();
  const {
    DescriptionField,
    description,
    TitleField,
    title,
    properties,
    required,
    uiSchema,
    idSchema,
  } = props;

  return (
    <>
      {(uiSchema["ui:title"] || title) && (
        <TitleField
          id={`${idSchema.$id}-title`}
          title={title}
          required={required}
        />
      )}
      {description && (
        <DescriptionField
          id={`${idSchema.$id}-description`}
          description={description}
        />
      )}
      <Grid container={true} spacing={2} className={classes.root}>
        {properties.map(
          (element: any, index: string | number | null | undefined) => {
            let xs =
              element.content.props.uiSchema["ui:field"] ??
              element.content.props.schema.type;
            xs = Array.isArray(xs) ? xs[0] : xs;
            // console.log(xs, element.content);
            if (element.content.props.uiSchema["ui:widget"] !== "hidden")
              return (
                <Grid
                  item={true}
                  xs={sizes[xs] ? sizes[xs] : sizes["default"]}
                  key={index}
                  style={{ marginBottom: "10px" }}
                >
                  {element.content}
                </Grid>
              );
            return false;
          }
        )}
      </Grid>
    </>
  );
};

/**
 * Setup the specific rendering for the fields in this form
 *
 * Custom widget for the Partner Lookup
 *
 * @todo Pass in additional custom fields that are Form specific, and remove those from below
 *
 */
const defaultUiSchema = {
  uid: {
    "ui:disabled": true,
    "ui:widget": "hidden",
    "ui:description": null,
  },
  lastEditedBy: { "ui:disabled": true },
  lastEdited: { "ui:disabled": true },
  dateAdded: { "ui:disabled": true },
  notes: {
    items: {
      "ui:widget": "textarea",
      "ui:removable": false,
      "ui:placeholder": "Any form of comments or notes",
    },
  },
};

/**
 *
 * @param {formData object, submit callback, validate callback, uiSchemaFields object} props    Expects poplated object following "schema"
 * @param uiSchemaFields    Passed in new fields or overrides for the defaultUiSchema object
 *
 * @todo - Add accordian between objects
 */

interface Props {
  formData: any;
  onSubmit: any;
  validate?: any;
  schema: any;
  uiSchemaFields: any;
}
export const FormComponent: FC<Props> = (props) => {
  const { formData, onSubmit, validate, schema, uiSchemaFields } = props;
  const classes = useStyles();
  const uiSchema = { ...defaultUiSchema, ...uiSchemaFields };

  return (
    <Form
      className={classes.rjsf}
      schema={schema}
      formData={formData}
      ObjectFieldTemplate={ObjectFieldTemplate}
      uiSchema={uiSchema}
      onSubmit={onSubmit}
      validate={validate}
      noHtml5Validate={true}
    >
      <Grid container={true} spacing={2} className={classes.formButtons}>
        <Grid item={true} xs={5} />
        <Grid item={true} xs={1}>
          <Button variant="outlined" color="primary" type="submit">
            Submit
          </Button>
        </Grid>
        <Grid item={true} xs={1}>
          <Button
            variant="outlined"
            color="secondary"
            type="button"
            onClick={(e) => {
              // formData.changed = false;
              onSubmit({ formData, cancel: true });
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item={true} xs={5} />
      </Grid>
    </Form>
  );
};

/**
 * Expected fields passed in the tag
 */
FormComponent.propTypes = {
  formData: PropTypes.object.isRequired, // Data to be populated in the form
  onSubmit: PropTypes.func.isRequired, // Submit function - default in GridComponent
  validate: PropTypes.func.isRequired, // Validate function - default in GridComponent
  schema: PropTypes.object.isRequired, // Schema of Model being edited in form
  uiSchemaFields: PropTypes.object, // Overrides or new UI fields specific to model
};
