import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {useLocation} from 'react-router-dom';
import {Container} from "@mui/material";
import LinkRouter from "./LinkRouter";

const breadcrumbNameMap : {[index: string] : string} = {
    '/partners': 'Partners',
    '/contracts': 'Contracts',
    '/contacts': 'Contacts'
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.grey[200],
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
}));

export default function RouterBreadcrumbs() {
    const location = useLocation();
    const classes = useStyles();
    const pathNames = location.pathname.split('/').filter((x) => x);


    return (
        <div className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <LinkRouter color="inherit" to="/">
                    Home
                </LinkRouter>
                {pathNames.map((value, index) => {
                    const last = index === pathNames.length - 1;
                    const to = `/${pathNames.slice(0, index + 1).join('/')}`;
                    const lastSegment = pathNames.slice(-1);

                    return last ? (
                        <Typography color="textPrimary" key={to}>
                            {breadcrumbNameMap[to] || lastSegment}
                        </Typography>
                    ) : (
                        <LinkRouter color="inherit" to={to} key={to}>
                            {breadcrumbNameMap[to] || lastSegment}
                        </LinkRouter>
                    );
                })}
            </Breadcrumbs>
        </div>
    )
}