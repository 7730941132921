/**
 * Primary Tabs container for editing Partner Facts
 *
 * @author Todd Hay
 *
 * @todo Implement overall Tabs Framework
 * @todo Setup content of each tab
 * @todo Figure out Dialog contol for editing
 *
 */

// Libraries
import React, {useEffect} from "react";
import {AppBar, Paper, Tab, Tabs, Box} from "@mui/material";

// Local Libraries
import PartnerGrid from "./partner/PartnerGrid";
import {ContractGrid} from "./contract/ContractGrid";
import {ContactGrid} from "./contact/ContactGrid";
import {
    Route,
    Link as RouterLink,
    useLocation, Routes, useNavigate
} from "react-router-dom";
import RouterBreadcrumbs from "./shared/RouterBreadcrumbs";
import makeStyles from "@mui/styles/makeStyles";
import PartnerPage from "./partner/PartnerPage";
import {AccountCircle, Assignment, CheckCircle, Group} from "@mui/icons-material";
import { UserType } from "../utils/types";
import AdminPage from "../pages/AdminPage";
import GuaranteePage from "./guarantees/GuaranteePage";
import ApprovalPage from "./approval/ContractApprovals/ApprovalPage";
import {ApprovalsPage} from "./approval/AllApprovals/ApprovalsPage";
import {ViewContracts} from "./contract/viewContracts/ViewContracts";
import {EditContract} from "./contract/editContract/EditContract";
import {EditContractContainer} from "./contract/editContract/EditContractContainer";

const useStyles = makeStyles((theme) => ({
    tabs: {
        backgroundColor: theme.palette.secondary.main,
        borderTop: "2px solid #EAAF00",
    },
    gridContainer: {
        marginTop: 16,
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 30,
    },
}));

export default function TabContainer(props: { user: UserType }) {
    const location = useLocation();
    const navigate = useNavigate();

    const classes = useStyles();
    const allowDelete: string | null = localStorage.getItem("allowDelete");
    const pathNames = location.pathname.split("/").filter((x) => x);

    useEffect(() => {
        if (location.pathname === "/") {
            navigate('/partners');
        }
    });
    type tab = {
        label: any;
        to: string;
    };
    const tabs: tab[] = [
        {
            label: (
                <div>
                    <Group style={{marginBottom: -7, marginRight: 5}}/>
                    Partners
                </div>
            ),
            to: "/partners",
        },
        {
            label: (
                <div>
                    <Assignment style={{marginBottom: -7, marginRight: 5}}/>
                    Contracts
                </div>
            ),
            to: "/contracts",
        },
        {
            label: (
                <div>
                    <AccountCircle style={{marginBottom: -7, marginRight: 5}}/>
                    Contacts
                </div>
            ),
            to: "/contacts",
        },
      {
        label: (
            <div>
              <CheckCircle style={{ marginBottom: -7, marginRight: 5 }} />
              Approvals
            </div>
        ),
        to: "/approvals",
      },
    ];

    const activeTabIndex: number = tabs.findIndex(
        (x) => x.to === `/${pathNames[0]}`
    );

  const AppBarComponent = () => (
    <AppBar position="static" className={classes.tabs}>
      <Tabs
        value={activeTabIndex}
        TabIndicatorProps={{ style: { background: "#FF0000" } }}
        textColor="inherit"
      >
        {tabs.map((x) => (
          <Tab key={`tab-${x.to}`} component={RouterLink} {...x} />
        ))}
      </Tabs>
    </AppBar>
  );

    return (
        <div>
            <Routes>
                <Route path="/partners/:partnerId" element={
                    <>
                        <RouterBreadcrumbs/>
                        <Box className={classes.gridContainer}>
                            <PartnerPage user={props.user}/>
                        </Box>
                    </>}/>
                <Route path="/guarantees/:contractId" element={
                    <>
                        <AppBarComponent/>
                        <GuaranteePage user={props.user}/>
                    </>}/>
                <Route path="/approvals/:contractId" element={<>
                    <AppBarComponent/>
                    <ApprovalPage user={props.user}/>
                </>}/>
                <Route path="/partners" element={<>
                    <AppBarComponent/>
                    <Paper elevation={3} className={classes.gridContainer}>
                        <PartnerGrid user={props.user}/>
                    </Paper>
                </>}/>
                <Route path="/contracts">
                    <Route path=":contractId" element={<>
                        <AppBarComponent/>
                        <EditContractContainer
                            user={props.user}
                            allowDelete={allowDelete === "true" ? true : false}
                        />
                    </>}/>
                    <Route path="" element={<>
                        <AppBarComponent/>
                        <ViewContracts
                            user={props.user}
                            allowDelete={allowDelete === "true" ? true : false}
                        />
                    </>}/>
                </Route>
                <Route path="/contacts">
                    <Route path=":contactId" element={<>
                        <AppBarComponent/>
                        <Paper elevation={3} className={classes.gridContainer}>
                            <ContactGrid user={props.user}/>
                        </Paper>
                    </>}/>
                    <Route path="" element={<>
                        <AppBarComponent/>
                        <Paper elevation={3} className={classes.gridContainer}>
                            <ContactGrid user={props.user}/>
                        </Paper>
                    </>}/>
                </Route>
              <Route path="/approvals" element={
                <>
                  <AppBarComponent/>
                  <Paper elevation={3} className={classes.gridContainer}>
                    <ApprovalsPage user={props.user}/>
                  </Paper>
                </>
              }/>
                <Route path="/admin" element={<>
                    <AppBarComponent/>
                    <Paper elevation={3} className={classes.gridContainer}>
                        <AdminPage/>
                    </Paper>
                </>}/>
            </Routes>
        </div>
    );
}
