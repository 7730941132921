import React, { ChangeEvent, useEffect, useState, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  TablePagination,
  TextField,
} from "@mui/material";
import { ApprovalsItem } from "./ApprovalsItem";
import { getBizApprovals } from "../../../utils/dataAccess";
import { Search } from "@mui/icons-material";
import { ApprovalListing } from "../../../model/approval/approval";
import { debounce } from "lodash";
import { UserType } from "../../../utils/types";

interface Props {
  user: UserType;
}

export const ApprovalsPage: React.FC<Props> = ({ user }) => {
  const useStyle = makeStyles((theme) => ({
    pageContainer: {
      paddingTop: "5vh",
      minHeight: "80vh",
    },
  }));
  const classes = useStyle();

  const [isLoading, setIsLoading] = useState(true);
  const [businessDiv, setBusinessDiv] = useState<string>("all");
  const [getAll, setGetAll] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [renderedApprovals, setRenderedApprovals] = useState(
    new Array<ApprovalListing>()
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleNewRowCount = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleSelectBusDiv = async (event: SelectChangeEvent<string>) => {
    setBusinessDiv(event.target.value);
    await search(event.target.value, searchTerm, getAll);
  };

  const handleGetAllChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGetAll(e.target.checked);
    search(businessDiv, searchTerm, e.target.checked);
  };

  const search = async (
    division: string | null = "all",
    searchTerm: string | null = null,
    getAll: boolean | null = null
  ) => {
    if (searchTerm?.length === 1) {
      setRenderedApprovals([]);
      return;
    }
    setIsLoading(true);
    try {
      await getBizApprovals(division, searchTerm, getAll).then((res) => {
        setRenderedApprovals(res);
      });
    } catch {
      setRenderedApprovals([]);
    } finally {
      setIsLoading(false);
    }
    setPage(0);
  };

  const searchDebounced = useMemo(() => debounce(search, 500), []);

  useEffect(() => {
    search(businessDiv, searchTerm, getAll);
  }, []);

  const handleSearchChange = async (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchTerm(e.target.value);
    await searchDebounced(businessDiv, e.target.value);
  };

  const renderLoading = () => {
    return (
      <>
        <Modal
          disableAutoFocus
          open={isLoading}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              height: "10vh",
              width: "10vh",
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "3px",
            }}
          >
            <CircularProgress />
          </Box>
        </Modal>
      </>
    );
  };

  return (
    <div className={classes.pageContainer}>
      {renderLoading()}
      <Container maxWidth={"xl"}>
        <Paper
          variant="outlined"
          sx={{ minHeight: "75vh", display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              alignSelf: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                minWidth: "80%",
                maxWidth: "90%",
                gap: "20px",
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <FormControl fullWidth>
                <TextField
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormControl sx={{ marginTop: "10px" }} fullWidth>
                <InputLabel id={"business-input"}>Business Division</InputLabel>
                <Select
                  id={"business-input"}
                  title={"Business Division"}
                  label={"Business Division"}
                  labelId={"business-input"}
                  defaultValue={"all"}
                  onChange={handleSelectBusDiv}
                >
                  <MenuItem value={"Business Development"}>
                    Business Development
                  </MenuItem>
                  <MenuItem value={"Finance"}>Finance</MenuItem>
                  <MenuItem value={"Content Operations"}>
                    Content Operations
                  </MenuItem>
                  <MenuItem value={"Ad Operations"}>Ad Operations</MenuItem>
                  <MenuItem value={"all"}>All</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={getAll}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleGetAllChecked(e);
                      }}
                    />
                  }
                  label={"Include Approved"}
                />
              </FormControl>
            </Box>
            {renderedApprovals.length !== 0 && (
              <TablePagination
                component="div"
                page={page}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                rowsPerPage={rowsPerPage}
                count={renderedApprovals.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleNewRowCount}
              />
            )}
          </Box>
          {renderedApprovals
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((ob) => {
              return (
                <ApprovalsItem
                  key={`${ob.uid}--${ob.dateAdded}`}
                  approvalListing={ob}
                  searchFnc={search}
                  searchObj={{ division: businessDiv, searchTerm: searchTerm }}
                  setIsLoading={setIsLoading}
                  user={user}
                />
              );
            })}
          {renderedApprovals.length !== 0 && (
            <TablePagination
              component="div"
              page={page}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              rowsPerPage={rowsPerPage}
              count={renderedApprovals.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleNewRowCount}
            />
          )}
        </Paper>
      </Container>
    </div>
  );
};
