import { getUserData } from "../components/Login";
export let resolveAccessTokenPromise = (accessToken: any) => {};
export let rejectAccessTokenPromise = (response: any) => {};
export const accessTokenPromise = new Promise((res, rej) => {
  resolveAccessTokenPromise = res;
  rejectAccessTokenPromise = rej;
});

const hasDataCheck = async () => {
  const expiresIn = localStorage.getItem("token-expiration");
  const accessToken = localStorage.getItem("user-token");
  const userInfo = localStorage.getItem("user-info");
  return !!expiresIn && !!accessToken && !!userInfo;
};

export const isTokenValidCheck = async () => {
  const accessToken = localStorage.getItem("user-token");
  if(!accessToken)return false;
  return getUserData(accessToken).then((res)=>{
    return true;
  }).catch((err)=>{
    console.error(err)
    return false
  })
};

export const isLoggedInWithValidToken = async () => {
  const hasData = await hasDataCheck();
  if (!hasData) return false;
  const isTokenValid = await isTokenValidCheck();
  if (!isTokenValid)return false;
  const accessToken = localStorage.getItem("user-token");
  resolveAccessTokenPromise(accessToken);
  return true;
};
