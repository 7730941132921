import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  CurrencyExchange,
  EventAvailable,
  FolderCopy,
  MoreTime,
  RemoveCircle,
} from "@mui/icons-material";
import { StatsTableRow } from "./TableUtils/StatsTableRow";
import { convertToDate } from "./TableUtils/testIfDate";

interface Props {
  approvalListing: any;
}

export const FinanceStats: React.FC<Props> = ({ approvalListing }: Props) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StatsTableRow
              label={"Date Added"}
              icon={<MoreTime />}
              stat={convertToDate(approvalListing.contract.dateAdded)}
            />
            <StatsTableRow
              label={"Effective Date"}
              icon={<EventAvailable />}
              stat={convertToDate(approvalListing.contract.effectiveDate)}
            />
            <StatsTableRow
              label={"End Date"}
              icon={<RemoveCircle />}
              stat={convertToDate(approvalListing.contract.endDate)}
            />
            <StatsTableRow
              label={"Type"}
              icon={<FolderCopy />}
              stat={approvalListing.contract.type}
            />
            <StatsTableRow
              label={"Share"}
              icon={<CurrencyExchange />}
              stat={approvalListing.contract.terms.revenueShare}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
