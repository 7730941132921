export class ApprovalListing {
  uid = "";
  cid = "";
  dateAdded: Date | null = null;
  dateEdited: Date | null = null;
  comment: string | null = null;
  isApproved: boolean | null = null;
  userEmail = "";
  team = "";
  contract: any = {};

  static mapFromServer(obj: any) {
    const approvalListing = new ApprovalListing();
    Object.assign(approvalListing, obj);
    approvalListing.dateAdded = new Date(obj.dateAdded);
    approvalListing.dateEdited = new Date(obj.dateEdited);
    approvalListing.contract.dateAdded = new Date(obj.contract.dateAdded);
    approvalListing.contract.effectiveDate = new Date(
      obj.contract.effectiveDate
    );
    approvalListing.contract.endDate = new Date(obj.contract.endDate);
    approvalListing.contract.lastEdited = new Date(obj.contract.lastEdited);
    return approvalListing;
  }
}
