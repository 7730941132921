import React, {useEffect, useState} from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";


interface Props {
  title: string;
  onExclusivityChange: (isExclusive: boolean, key: string) => void;
  allLabelText?: string;
  currentStatus: boolean | undefined
}

export const ExclusivitySelect = ({
  title,
  onExclusivityChange,
  allLabelText,
    currentStatus
}: Props) => {
  const [select, setSelect] = useState<number>(0);

  useEffect(()=>{
    setSelect(Number(currentStatus))
  },[])

  const handleSelectChange = (e: SelectChangeEvent) => {
    setSelect(Number(e.target.value));
    onExclusivityChange(Boolean(e.target.value), title);
  };



  return (
    <>
      <FormControl fullWidth>
        <InputLabel id={"select-test"}>{title}</InputLabel>
        <Select
          labelId={"select-test"}
          label={title}
          value={select.toString()}
          onChange={handleSelectChange}
        >
          <MenuItem value={0}>Includes</MenuItem>
          <MenuItem value={1}>Excludes</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
