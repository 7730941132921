/**
 * Google OAuth Login Component
 *
 */

// Libraries
import React, { Dispatch, useEffect, useMemo, useState } from "react";
import {Avatar, Box, Stack, Typography} from "@mui/material";
import googleButton from "./shared/btn_google_signin_light.png";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import {
  isLoggedInWithValidToken,
  rejectAccessTokenPromise,
  resolveAccessTokenPromise,
} from "../utils/authorization";
import axios from "axios";
import { addHours } from "date-fns";
import {Tooltip} from "@material-ui/core";
import {Logout} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {settings} from "../settings";

/**
 * Component to hold the Google Login Button
 *
 * @todo Clean-up the user icon in the logout button
 * @todo Remove the isUserLoggedIn state and replace with simple check to user.accessToken
 * @param props
 */
const Login = (props: {
  user: any;
  onLogin: (res: any) => void;
  loadingStatus: boolean;
  loginStatus: boolean;
  processUserLogin: () => void;
}) => {
  //
  useEffect(() => {
    props.processUserLogin()
  }, []);




  const calculateExpiration = () => {
    const later = addHours(new Date(), 1);
    localStorage.setItem("token-expiration", later.toString());
  };

  const handleGoogleLoginSuccess = async (
    tokenResponse: Pick<
      TokenResponse,
      | "scope"
      | "prompt"
      | "state"
      | "access_token"
      | "expires_in"
      | "hd"
      | "token_type"
    >
  ) => {
    localStorage.setItem("user-token", tokenResponse.access_token);
    await getUserData(tokenResponse.access_token)
      .then((data) => {
        const emailDomain = data.email.split('@');
        if(emailDomain[1] === settings.REACT_APP_HOSTED_DOMAIN){
          localStorage.setItem(
              "user-info",
              JSON.stringify({
                profileObj: {
                  email: data.email,
                  imageUrl: data.picture,
                  name: data.name,
                },
              })
          );
        } else {
          return Promise.reject(
            `Invalid email domain for for login; expected ${settings.REACT_APP_HOSTED_DOMAIN}, received ${emailDomain[1]}`
          );
        }
      })
      .catch((err) => {
        console.error("Error retrieving user data:", err);
      });
    calculateExpiration();
    resolveAccessTokenPromise(tokenResponse.access_token);
    props.processUserLogin();
  };

  const authFlow = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleGoogleLoginSuccess(tokenResponse);
    },
    onError: (e) => console.error(e),
    onNonOAuthError: (e) => console.error(e),
    // hosted_domain: "plexapp.com",
  });

  const handleLogout = () =>{
    localStorage.removeItem('user-info')
    localStorage.removeItem('user-token')
    props.processUserLogin()
  }

  return (
    <>
      {props.loginStatus ? (
        <>
          <Box>
            <Stack spacing={2} alignItems={"center"} direction={"row"}>
              <Avatar src={props.user.profileObj?.imageUrl} />
              <Typography variant={"h6"}>{props.user.profileObj?.name || ""}</Typography>
              <Tooltip title={"Logout"}>
                <IconButton sx={{color: "white"}} onClick={handleLogout}>
                  <Logout />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
        </>
      ) : (
        !props.loadingStatus && (
          <Box
            onClick={() => {
              authFlow();
            }}
            sx={{
              display: "flex",
              objectFit: "fill",
              height: "50px",
              width: "auto",
              "&:hover": { cursor: "pointer" },
            }}
          >
            {}
            <img style={{ height: "auto", width: "auto" }} src={googleButton} />
          </Box>
        )
      )}
    </>
  );
};

export const getUserData = async (accessToken: string) => {
  try {
    const { data } = await axios.get(
      "https://www.googleapis.com/oauth2/v1/userinfo",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export default Login;
