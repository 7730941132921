import React from "react";
import AddIcon from "@mui/icons-material/Add";
import makeStyles from '@mui/styles/makeStyles';
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    iconButton: {}
}));

function AddIconButton({handleClick}) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Tooltip title={"Add new"}>
                <IconButton className={classes.iconButton} onClick={handleClick} size="large">
                    <AddIcon/>
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
}

AddIconButton.propTypes = {
    handleClick: PropTypes.func.isRequired
}

export default AddIconButton;