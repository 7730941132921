import React, { useEffect, useState } from "react";
import { Contract, lineItem, Terms } from "../../utils/types";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MuiForm5 as Form } from "@rjsf/material-ui";
import { termsUISchema } from "./termsUISchema";
import { ExclusivitySelect } from "./ExclusivitySelect";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/lab";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import { LoadingModal } from "../shared/LoadingModal";
import { IChangeEvent } from "@rjsf/core";
import Alert from "@mui/material/Alert";
import { insert } from "../../utils/dataAccess";
import { ContractObjectFieldTemplate } from "../contract/subComponents/ContractObjectFieldTemplate";
import DialogActions from "@mui/material/DialogActions";
import ContentTierSelect from "./ContentTierSelect";

const contractsSchema = require("../../model/contract/Contract.json");
const termsSchema = {
  ...contractsSchema.properties.terms,
  title: "Terms",
  description: "Terms Description",
};
delete termsSchema.properties.territory;

interface Props {
  setDialogClosed: () => void;
  cid: number | null | undefined;
  getAll: () => void;
}

type severityString = "success" | "error" | "warning" | "info";

interface AlertData {
  alertHeader: string;
  alertInfo: string;
}

type ErrorMessages<T> = {
  [Key in keyof T]?: string;
};

export const AddLineItemDialog = ({ cid, setDialogClosed, getAll }: Props) => {
  const useStyle = makeStyles((theme) => ({
    rjsf: {
      margin: 8,
      overflow: "scroll",
    },
    formButtons: {
      textAlign: "center",
      margin: theme.spacing(2),
    },
    form: {
      margin: "20px",
    },
  }));
  const classes = useStyle();

  const [formData, setFormData] = useState<Partial<lineItem>>();
  const [formReady, setFormReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [snack, setSnack] = useState<{
    show: boolean;
    alert: AlertData[];
    severity: severityString;
  }>({
    show: false,
    alert: [{ alertHeader: "", alertInfo: "" }],
    severity: "success",
  });
  const [isOpen, setIsOpen] = useState<boolean>(true);

  useEffect(() => {
    setFormData((previousFormData) => {
      return {
        ...previousFormData,
        cid,
      };
    });
  }, [cid, formData?.terms]);

  const handleBooleanOrStringChange = (
    e: boolean | string,
    key: keyof lineItem
  ) => {
    formData && setFormData({ ...formData, [key]: e });
  };

  const inputTemplate = () => {};

  const handleArrayChange = (
    e: React.SyntheticEvent,
    newValue: string[],
    key: keyof lineItem
  ) => {
    formData && setFormData({ ...formData, [key]: newValue });
  };

  const handleDateChange = (
    newValue: string | null | undefined,
    key: string
  ) => {
    if (!newValue) return;
    formData &&
      setFormData({
        ...formData,
        [key]: new Date(newValue).toISOString().slice(0, 10).replace("T", " "),
      });
  };

  const handleTermsChange = (e: IChangeEvent) => {
    setFormData((previousFormData) => {
      return {
        ...previousFormData,
        terms: e.formData,
      };
    });
  };

  const handleSnackClose = () => {
    setSnack({
      show: false,
      severity: "info",
      alert: [{ alertHeader: "", alertInfo: "" }],
    });
  };

  const parseValidation = (formData: Partial<lineItem> | undefined) => {
    if (formData === undefined) return { isValidated: false };

    const form = formData as lineItem;
    const requiredLineItemFields: (keyof lineItem)[] = [
      "startDate",
      "endDate",
      "content",
    ];
    const requiredTermsFields: (keyof Terms)[] = ["shareType"];

    const requiredLineItemCombinations = [[]];
    const requiredTermsCombinations = [[], []];

    const missingRequiredLineItemFields = [];
    const missingRequiredTermsFields = [];
    const missingRequiredLineItemCombinations = [];
    const missingRequiredTermsCombinations = [];

    const terms = formData.terms;

    for (let i = 0; i < requiredLineItemFields.length; i++) {
      !formData.hasOwnProperty(requiredLineItemFields[i].toString()) &&
        missingRequiredLineItemFields.push(requiredLineItemFields[i]);
    }
    for (let i = 0; i < requiredTermsFields.length; i++) {
      terms &&
        !terms.hasOwnProperty(requiredTermsFields[i].toString()) &&
        missingRequiredTermsFields.push(requiredTermsFields[i]);
    }
    for (let i = 0; i < requiredLineItemCombinations.length; i++) {
      !requiredLineItemCombinations[i].every((key) =>
        formData.hasOwnProperty(key)
      ) &&
        missingRequiredLineItemCombinations.push(
          requiredLineItemCombinations[i]
        );
    }

    if (
      missingRequiredLineItemFields.length ||
      missingRequiredLineItemCombinations.length ||
      missingRequiredTermsFields.length
    ) {
      return {
        isValidated: false,
        missingRequiredLineItemFields: missingRequiredLineItemFields,
        missingRequiredLineItemCombinations:
          missingRequiredLineItemCombinations,
        missingRequiredTermsFields: missingRequiredTermsFields,
      };
    }

    return { isValidated: true };
  };

  const fieldParameters = (
    formData: lineItem,
    fieldName: string,
    requiredFieldCombinations: string[],
    forbiddenFieldCombinations: string[]
  ) => {};

  const sanitizeFormTerms = (terms: Terms | undefined) => {
    if (!terms) return;
    switch (terms.shareType) {
      case "revenue":
      case "revenue - partner":
        terms.inventoryShare = 0;
        break;
      case "inventory - plex":
      case "inventory - partner":
        terms.revenueShare = 0;
        break;
      case "cpm":
      case "fixed":
        terms.inventoryShare = 0;
        terms.revenueShare = 0;
        break;
      default:
        console.log("unknown shareType state");
        break;
    }
    setFormData((previousFormData) => {
      return {
        ...previousFormData,
        terms,
      };
    });
  };

  const makeAlertFromArray = (alertHeader: string, issueData: string[]) => {
    const text = issueData.join(" , ");
    return { alertHeader: alertHeader, alertInfo: text };
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const validationInformation = parseValidation(formData);
    if (!validationInformation.isValidated) {
      const alertArray = [];

      validationInformation.missingRequiredTermsFields?.length &&
        alertArray.push(
          makeAlertFromArray(
            "Missing Required Terms:",
            validationInformation.missingRequiredTermsFields
          )
        );
      validationInformation.missingRequiredLineItemFields?.length &&
        alertArray.push(
          makeAlertFromArray(
            "Missing Required Fields:",
            validationInformation.missingRequiredLineItemFields
          )
        );
      setSnack({ show: true, alert: alertArray, severity: "warning" });
      return;
    }
    sanitizeFormTerms(formData?.terms);
    setLoading(true);
    insert("line_items", formData)
      .then((res) => {
        setLoading(false);
        getAll();
        setSnack({
          show: true,
          alert: [
            { alertHeader: "Line Item Successfully Added", alertInfo: "" },
          ],
          severity: "success",
        });
        setTimeout(() => {
          setDialogClosed();
        }, 3 * 1000);
      })
      .catch((err) => {
        setLoading(false);
        setSnack({
          show: true,
          alert: [
            { alertHeader: "Line Item Insert Error", alertInfo: `${err}` },
          ],
          severity: "error",
        });
      });
  };

  const validate = (formData: Terms, errors: any) => {
    if (formData.maximumCommission === 0) {
      errors.maximumCommission.addError("Maximum Commissions can't be zero");
    }

    console.log("Errors--->", errors);

    let hasError = false;
    for (const props of Object.keys(errors)) {
      if (errors[props].__errors && errors[props].__errors.length > 0) {
        hasError = true;
      }
    }
    if (hasError) {
      console.log("Form has an error");
      setFormReady(false);
    } else {
      console.log("Form does not have an error");

      setFormReady(true);
    }
    return errors;
  };
  const handleDialogClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <LoadingModal isLoading={loading} />
      <Dialog
        open={isOpen}
        fullWidth={true}
        onClose={handleDialogClose}
        maxWidth="xl"
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <DialogActions>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <Typography variant="h6">Add Line Item</Typography>
                  <IconButton onClick={setDialogClosed}>
                    <Close />
                  </IconButton>
                </Stack>
              </DialogActions>
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <Stack className={classes.form} spacing={1}>
                <h1>Content</h1>
                <ExclusivitySelect
                  title={"Content Scope"}
                  onExclusivityChange={(isExclusive) => {
                    handleBooleanOrStringChange(
                      isExclusive,
                      "contentIsExclusive"
                    );
                  }}
                  currentStatus={formData?.contentIsExclusive}
                />
                <Autocomplete
                  multiple
                  id="id"
                  freeSolo
                  options={[""]}
                  defaultValue={[]}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      // eslint-disable-next-line react/jsx-key
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label={"Content"} />
                  )}
                  onChange={(e, newValue) => {
                    handleArrayChange(e, newValue, "content");
                  }}
                />
                <ContentTierSelect
                  onContentTierChange={(contentTier: string) => {
                    handleBooleanOrStringChange(contentTier, "contentTiers");
                  }}
                />

                <h1>Devices</h1>
                <ExclusivitySelect
                  title={"Device Exclusivity"}
                  onExclusivityChange={(isExclusive) => {
                    handleBooleanOrStringChange(
                      isExclusive,
                      "devicesIsExclusive"
                    );
                  }}
                  currentStatus={formData?.devicesIsExclusive}
                />
                <Autocomplete
                  multiple
                  id="id"
                  freeSolo
                  options={[""]}
                  defaultValue={[]}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      // eslint-disable-next-line react/jsx-key
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label={"Devices"} />
                  )}
                  onChange={(e, newValue) => {
                    handleArrayChange(e, newValue, "devices");
                  }}
                />

                <Box>
                  <h1>Locations</h1>
                </Box>
                <ExclusivitySelect
                  title={"Location Exclusivity"}
                  onExclusivityChange={(isExclusive) => {
                    handleBooleanOrStringChange(
                      isExclusive,
                      "locationsIsExclusive"
                    );
                  }}
                  currentStatus={formData?.locationsIsExclusive}
                />
                <Autocomplete
                  multiple
                  id="id"
                  freeSolo
                  options={[""]}
                  defaultValue={[]}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      // eslint-disable-next-line react/jsx-key
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label={"Locations"} />
                  )}
                  onChange={(e, newValue) => {
                    handleArrayChange(e, newValue, "locations");
                  }}
                />
                <Box>
                  <h1>Start/End Date</h1>
                  <Stack direction={"row"} spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Start Date"
                        onChange={(e) => {
                          handleDateChange(e, "startDate");
                        }}
                        value={formData?.startDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        onChange={(e) => {
                          handleDateChange(e, "endDate");
                        }}
                        value={formData?.endDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Box>
              </Stack>
              <Form
                liveValidate
                formData={formData?.terms}
                ObjectFieldTemplate={ContractObjectFieldTemplate}
                schema={termsSchema}
                uiSchema={termsUISchema}
                onChange={handleTermsChange}
                validate={validate}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
              <DialogActions>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="right"
                >
                  <Button disabled={!formReady} onClick={handleSubmit}>
                    Submit
                  </Button>
                  <Button onClick={handleDialogClose}>Cancel</Button>
                </Stack>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snack.show}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert severity={snack.severity} onClose={handleSnackClose}>
          {snack.alert?.map((ob: AlertData) => {
            return (
              <>
                <Typography variant="h4">{ob.alertHeader}</Typography>
                <Typography variant="h5">{ob.alertInfo}</Typography>
              </>
            );
          })}
        </Alert>
      </Snackbar>
    </>
  );
};
