import LookUp from "../../LookUpDropDown";

/**
 * Setup the specific rendering for the fields in this form
 *
 * Custom widget for the Partner Lookup
 */
export const guaranteeUiSchema = {
    "ui:order": ["uid", "pid", "cid", "*", "additionalCids"],
    uid: {
        "ui:disabled": true,
        "ui:widget": "hidden",
        "ui:description": null
    },
    additionalCids: {
        items: {
            "ui:removable": true,
            "ui:orderable": false
        },
    },
}