import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export interface DialogState {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onConfirm: () => void;
}
type ConfirmCallback = () => Promise<unknown> | void;

function useDialogState(props: {
  onClose: () => void;
  onConfirm?: ConfirmCallback;
}): DialogState {
  const [isOpen, setIsOpen] = useState(false);

  const { onConfirm: onConfirmFromProps, onClose: onCloseFromProps } = props;

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
    onCloseFromProps();
  }, [onCloseFromProps]);

  const onConfirm = useCallback(async () => {
    if (onConfirmFromProps) {
      await onConfirmFromProps();
    }

    onClose();
  }, [onConfirmFromProps, onClose]);

  //
  // Effects

  useEffect(() => {
    return onClose;
  }, [onClose]);

  return {
    isOpen,
    onOpen,
    onClose,
    onConfirm,
  };
}

export default function useDialogsState<
  G extends Record<string, ConfirmCallback | null>
>(
  group: G
): {
  [key in keyof G]: DialogState;
} {
  const [currentOpenDialog, setCurrentOpenDialog] = useState<keyof G>();
  const entries = useMemo(() => Object.entries(group), [group]);

  const closeCallback = useCallback(() => {
    setCurrentOpenDialog(undefined);
  }, []);

  const dialogStates = entries.map(([, confirmCallback]) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useDialogState({
      onConfirm: confirmCallback || undefined,
      onClose: closeCallback,
    })
  );
  const dialogStatesRef = useRef(dialogStates);

  dialogStatesRef.current = dialogStates;

  return useMemo(() => {
    const mappedEntries = entries.map(([key], i) => {
      const dialogState = dialogStatesRef.current[i];
      const { isOpen, onClose, onConfirm, onOpen } = dialogState;

      return [
        key,
        {
          isOpen,
          onClose: () => {
            if (currentOpenDialog !== key) {
              return;
            }
            onClose();
          },
          onConfirm,
          onOpen: () => {
            if (currentOpenDialog) {
              return;
            }

            setCurrentOpenDialog(key);
            onOpen();
          },
        },
      ];
    });

    return Object.fromEntries(mappedEntries);
  }, [currentOpenDialog, entries]);
}
