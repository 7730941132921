import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { EditContract } from "./EditContract";
import { Contract, UserType } from "../../../utils/types";
import { getContractAndLineItems } from "../../../utils/dataAccess";
import { useParams } from "react-router-dom";
import { LineItemEntryList } from "../../lineItems/lineItemEntryList";
import useTabs from "../../../utils/useTabs";
import TvodLineItemsList from "../../lineItems/TvodLineItemsList";

interface AdminProps {
  user: UserType;
  allowDelete: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const EditContractContainer = ({ user, allowDelete }: AdminProps) => {
  const { tabs, selectedTab } = useTabs({
    titles: ["Contract", "Line Items"],
  });
  const [contract, setContract] = useState<Contract>();
  type IdParams = {
    contractId: string;
  };

  const { contractId } = useParams<IdParams>();

  const refreshData = useCallback(() => {
    getContractAndLineItems(Number(contractId))
      .then((res) => {
        setContract(res.data[0]);
      })
      .catch((err) => {});
  }, [contractId]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return (
    <>
      <Grid container xs={12} sx={{ mx: 5, my: 5 }}>
        <Typography variant="h3">{contract?.name}</Typography>
      </Grid>

      {tabs}
      <TabPanel index={0} value={selectedTab}>
        {contract && (
          <EditContract
            user={user}
            allowDelete={allowDelete}
            contract={contract}
          />
        )}
      </TabPanel>

      <TabPanel index={1} value={selectedTab}>
        {contract?.type === "tvod" &&
        contract.lineItems &&
        typeof contract.uid === "number" &&
        typeof contract.pid === "number" ? (
          <TvodLineItemsList contract={contract} onRefresh={refreshData} />
        ) : null}
        {contract?.type !== "tvod" && contract?.lineItems ? (
          <LineItemEntryList
            lineItems={contract.lineItems}
            cid={Number(contractId)}
            getAll={refreshData}
          />
        ) : null}
      </TabPanel>
    </>
  );
};
