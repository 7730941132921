/**
 * Contract Grid for editing Contract Facts
 *
 * @author Todd Hay
 *
 */

// Libraries
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

// Fields to display in the form
import { Button, CircularProgress, Grid, Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import deepEqual from "deep-equal";
import {
  getAll,
  getContractAndLineItems,
  insert,
  update,
} from "../../../utils/dataAccess";
import { MuiForm5 as Form } from "@rjsf/material-ui";
import makeStyles from "@mui/styles/makeStyles";
import { ContractObjectFieldTemplate } from "../subComponents/ContractObjectFieldTemplate";
import { contractUiSchema } from "../subComponents/ContractUiSchema";
import { useNavigate, useParams } from "react-router-dom";
import { ISubmitEvent } from "@rjsf/core";
import {
  Approval,
  Contract,
  severityString,
  Snack,
  UserType,
} from "../../../utils/types";

const vodSchema = require("../../../model/contract/vodContract.json");
const tvodSchema = require("../../../model/contract/tvodContract.json");
const epgSchema = require("../../../model/contract/epgContract.json");
const demandSchema = require("../../../model/contract/demandContract.json");
const ssaiSchema = require("../../../model/contract/ssaiContract.json");
const distributionSchema = require("../../../model/contract/distributionContract.json");

const useStyles = makeStyles((theme) => ({
  rjsf: {
    margin: 8,
  },
  formButtons: {
    textAlign: "center",
    margin: theme.spacing(2),
  },
}));

interface Props {
  contract: Contract;
  user: UserType;
  embed?: boolean;
  selectPartner?: any;
  showTitle?: boolean;
  allowDelete: boolean;
}

export const EditContract: FC<Props> = (props): JSX.Element => {
  const {
    user,
    // embed,
    selectPartner,
    // allowDelete,
    // showTitle = true,
    // contract,
  } = props;
  type IdParams = {
    contractId: string;
  };
  const { contractId } = useParams<IdParams>();
  const navigate = useNavigate();
  // const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [allContracts, setAllContracts] = useState([]);
  const [contractToEdit, setContractToEdit] = useState<Contract | null>(null);
  const [currentSchema, setCurrentSchema] = useState(vodSchema);
  const [contractType, setContractType] = useState<string | null>("vod");
  const [originalContractType, setOriginalContractType] = useState<
    string | undefined
  >();
  // const [contractToDelete, setContractToDelete] = useState<number | null>(null);
  // const [lineItems, setLineItems] = useState<lineItem[] | null>(null);

  const [snack, setSnack] = useState<Snack>({
    show: false,
    alert: "Nothing to see here...",
    severity: "success",
  });

  const teams = [
    "Business Development",
    "Finance",
    "Content Operations",
    "Ad Operations",
  ];
  const initialApprovalsArray: Approval[] = teams.map((x) => ({
    userEmail: null,
    isApproved: "unreviewed",
    userTeam: x,
    dateReviewed: null,
    comments: null,
  }));

  const classes = useStyles();

  const getContracts = useCallback(() => {
    const whereClause: string[] = ["active", `uid = ${contractId}`];
    if (selectPartner) {
      whereClause.push(`pid = ${selectPartner}`);
    }
    getAll("contracts", ["*", "p.partner AS partner_name"], whereClause)
      .then((x) => {
        const migratedContracts = x.map(mapFromDatabase);
        setAllContracts(migratedContracts);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectPartner, contractId]);

  const mapFromDatabase = (contract: { approvals: any; isPending: null }) => {
    const newContractApprovals = contract.approvals;
    for (let i = 0; i < newContractApprovals.length; i++) {
      switch (newContractApprovals[i].isApproved) {
        case true:
          newContractApprovals[i].isApproved = "approved";
          break;
        case false:
          newContractApprovals[i].isApproved = "disapproved";
          break;
        case null:
          newContractApprovals[i].isApproved = "unreviewed";
          break;
        default:
          newContractApprovals[i].isApproved = "Error";
      }
    }

    return {
      ...contract,
      approvals: newContractApprovals,
      isPending:
        typeof contract.isPending === "undefined" || contract.isPending === null
          ? false
          : contract.isPending,
    };
  };

  const mapToDatabase = (contract: Contract) => {
    contract.approvals.map((x: Approval) => {
      switch (x.isApproved) {
        case "approved":
          x.isApproved = true;
          x.userEmail = user.profileObj.email;
          x.dateReviewed = new Date().toISOString().slice(0, -1);
          break;
        case "disapproved":
          x.isApproved = false;
          x.userEmail = user.profileObj.email;
          x.dateReviewed = new Date().toISOString().slice(0, -1);
          break;
        case "unreviewed":
          x.isApproved = null;
          x.userEmail = null;
          x.dateReviewed = null;
          break;
        default:
          console.log("unknown isApproved state");
          break;
      }
    });
  };

  useEffect(() => {
    getContractAndLineItems(Number(contractId)).then((res) => {
      setContractToEdit(res.data[0]);
      setSchema(res.data[0]);
      setLoading(false);
    });
  }, []);

  const setSnackDetails = (alert: string, severity: severityString) => {
    setSnack({
      show: true,
      severity: severity,
      alert,
    });
  };

  const handleFormClose = (formUpdatedOrSubmitted: boolean) => {
    if (formUpdatedOrSubmitted) {
      getContracts();
    }
    setContractToEdit(null);
    setContractType("vod");
  };

  const emptyUnneededValues = (formTerms: {
    shareType: string;
    inventoryShare: number;
    revenueShare: number;
  }) => {
    switch (formTerms.shareType) {
      case "revenue":
      case "revenue - partner":
        formTerms.inventoryShare = 0;
        break;
      case "inventory - plex":
      case "inventory - partner":
        formTerms.revenueShare = 0;
        break;
      case "cpm":
      case "fixed":
        formTerms.inventoryShare = 0;
        formTerms.revenueShare = 0;
        break;
      default:
        console.log("unknown shareType state");
        break;
    }
  };

  const handleSubmit = async (
    e: ISubmitEvent<Contract>,
    nativeEvent: React.FormEvent<HTMLFormElement>
  ) => {
    const formData = e.formData;

    if (formData.uid === null) {
      formData.new = true;
    }
    setSubmissionLoading(true);
    if (!formData) {
      console.error("No formData");
      setSubmissionLoading(false);
      setContractToEdit(null);
      setSnackDetails("Cancelled", "info");
      return;
    }

    emptyUnneededValues(formData.terms);
    mapToDatabase(formData);

    const totalApprovals = formData.approvals.reduce(
      (c: number, x: { isApproved: any }) => (x.isApproved ? 1 : 0) + c,
      0
    );

    const totalReviews = formData.approvals.reduce(
      (c: number, x: { isApproved: any }) => (x.isApproved != null ? 1 : 0) + c,
      0
    );

    if (totalReviews === 4) {
      formData.isPending = false;
      if (totalApprovals === 4) {
        formData.fullyApproved = true;
      }
    }

    const amendedContract: Contract = allContracts.filter(
      (x: Contract) => formData.amendedContractId === x.uid
    )[0];
    if (
      amendedContract !== undefined &&
      formData.effectiveDate &&
      formData.endDate &&
      amendedContract.endDate &&
      amendedContract.effectiveDate
    ) {
      if (
        !(
          formData?.effectiveDate > amendedContract?.endDate &&
          formData.endDate > formData.effectiveDate
        )
      ) {
        setSnackDetails(
          "Warning: these dates overlap a related contract.",
          "warning"
        );
        setSubmissionLoading(false);
        return;
      }
    }

    // Check to see if record has changed
    if (!deepEqual(formData, contractToEdit) || hasContractTypeChanged) {
      formData.lastEditedBy = user.profileObj.email;
      if (formData.new) {
        insert("contracts", formData)
          .then(() => {
            setSnackDetails("Record Saved", "success");
            setSubmissionLoading(false);
            navigate(`/partners/${formData.pid}`);
          })
          .catch((err) => {
            setSnackDetails("Record not inserted", "error");
            console.log("recordGrid Catch");
            console.dir(err);
            setSubmissionLoading(false);
          });
      } else
        update("contracts", formData, contractToEdit)
          .then(() => {
            setSnackDetails("Record Saved", "success");
            setSubmissionLoading(false);
            navigate(`/partners/${formData.pid}`);
          })
          .catch((err) => {
            setSnackDetails("Update Error", "error");
            setSubmissionLoading(false);
            // An API error or partial failure occurred.
            console.log(err);
            if (err.name === "PartialFailureError") {
              // Some rows failed to insert, while others may have succeeded.

              // err.errors (object[]):
              // err.errors[].row (original row object passed to `insert`)
              console.dir("errors object", err.errors[0]);
              console.log("reason code", err.errors[0].errors[0].reason);
              console.log("message", err.errors[0].errors[0].message);
              // err.errors[].errors[].message
              setSnackDetails(
                `Insert Error: ${err.errors[0].errors[0].message}`,
                "error"
              );
            }
          });
    } else {
      // Record hasn't changed - user cancelled.
      setSnackDetails("No changes made. Operation cancelled.", "info");
      setSubmissionLoading(false);
    }
    setContractType("vod");
    getContracts();
  };

  const handleSnackClose = () => {
    setSnack({ show: false, alert: null, severity: "success" });
  };

  const onEdit = (passedContractId: number, passedContracts: any) => {
    if (!contractId) {
      navigate(`/contracts/${passedContractId}`);
    }
    const contract = (passedContracts || allContracts).filter(
      (x: { uid: number }) => x.uid === passedContractId
    )[0];
    const amendedContract: Contract = allContracts.filter(
      (x: Contract) => contract.amendedContractId === x.uid
    )[0];
    if (
      amendedContract &&
      amendedContract.endDate &&
      amendedContract.effectiveDate
    ) {
      if (
        contract.effectiveDate > amendedContract?.endDate &&
        contract.endDate > contract.effectiveDate
      ) {
        console.log("no overlap");
      } else {
        setSnackDetails(
          "Warning: these dates overlap a related contract.",
          "warning"
        );
      }
    }
    setSchema(contract);
    setContractToEdit(contract);
  };

  const localColumns = localStorage.getItem("contractGrid");
  let displayedColumns;
  if (localColumns != null) {
    const localColumnsObject: { columns: any } = JSON.parse(localColumns);
    displayedColumns = localColumnsObject.columns.filter(
      (obj: any) => obj.display == "true"
    );
  }

  const validate = (formData: Contract, errors: any) => {
    // Check for duplicate ID on new contracts, or when partner_id has changed.
    if (formData.new || !(formData.partner_id === contractToEdit?.partner_id)) {
      const checkForDuplicates = (o: { partner_id: string | null }) => {
        if (o.partner_id == null) {
          return false;
        }
        return (
          formData.partner_id?.toLowerCase() === o.partner_id.toLowerCase() &&
          !formData.amendedContractId
        );
      };
      const duplicateExists = allContracts.some(checkForDuplicates);
      if (duplicateExists) {
        errors.partner_id.addError("Partner ID must be unique");
      }
    }
    return errors;
  };

  function setSchema(formData: Contract) {
    setContractType(formData.type || contractType);
    switch (formData.type) {
      case "vod":
        setCurrentSchema(vodSchema);
        break;
      case "ssai":
        setCurrentSchema(ssaiSchema);
        break;
      case "epg":
        setCurrentSchema(epgSchema);
        break;
      case "tvod":
        setCurrentSchema(tvodSchema);
        break;
      case "demand":
        setCurrentSchema(demandSchema);
        break;
      case "distribution":
        setCurrentSchema(distributionSchema);
        break;
      case "distribution-ads":
        setCurrentSchema(distributionSchema);
        break;
      case "distribution-tvod":
        setCurrentSchema(distributionSchema);
        break;
      case "distribution-plex-pass":
        setCurrentSchema(distributionSchema);
        break;
      default:
        console.error(
          "Schema could not be determined in EditContract.tsx function setSchema"
        );
        break;
    }
  }

  const hasContractTypeChanged = useMemo(() => {
    return (
      originalContractType !== undefined &&
      originalContractType !== contractToEdit?.type
    );
  }, [contractToEdit?.type, originalContractType]);

  const onFormChange = (formData: Contract) => {
    if (contractToEdit !== null) {
      if (contractToEdit.type !== formData.type) {
        setOriginalContractType(contractToEdit.type);
        setContractToEdit(formData);
      }
      setSchema(formData);
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress style={{ marginTop: "4em", marginBottom: "4em" }} />
      </div>
    );
  }

  // let dialogTitle;
  // if (contractToEdit !== null) {
  //   if (contractToEdit.uid != null) {
  //     dialogTitle = "Edit record details";
  //   }
  //   if (contractToEdit.amendedContractId !== null) {
  //     dialogTitle = "Add contract amendment";
  //   }
  //   if (contractToEdit.amendedContractId === undefined) {
  //     dialogTitle = "Add new contract";
  //   }
  // }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={3}></Grid>
        <Grid item xs={8} />
        <Grid item xs={1} style={{ textAlign: "right" }}></Grid>
      </Grid>
      {contractToEdit && (
        <Form
          className={classes.rjsf}
          ObjectFieldTemplate={ContractObjectFieldTemplate}
          schema={currentSchema}
          formData={contractToEdit}
          uiSchema={contractUiSchema}
          onSubmit={handleSubmit}
          validate={validate}
          onChange={(e) => onFormChange(e.formData)}
        >
          <Grid container spacing={2} className={classes.formButtons}>
            <Grid item xs={5} />
            <Grid item xs={1}>
              {submissionLoading ? (
                <CircularProgress />
              ) : (
                <Button variant="outlined" color="primary" type="submit">
                  Submit
                </Button>
              )}
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="outlined"
                color="secondary"
                type="button"
                onClick={(formData: Contract) => {
                  formData.changed = false;
                  handleFormClose(true);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={5} />
          </Grid>
        </Form>
      )}

      <Snackbar
        open={snack.show}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <Alert severity={snack.severity} onClose={handleSnackClose}>
          {snack.alert}
        </Alert>
      </Snackbar>
    </div>
  );
};
