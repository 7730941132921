import React from 'react';
import PropTypes from 'prop-types';
import TabContainer from "./components/TabPanels";
import { BrowserRouter as Router } from "react-router-dom";
import {UserType} from "./utils/types";

export const MainRouter = (props: {user: UserType}) => {
    const {user} = props;
    return (
      <Router>
          <TabContainer user={user} />
      </Router>
    );
};

MainRouter.propTypes = {
    user: PropTypes.object.isRequired
};

export default MainRouter;