/**
 * Header component of App
 *
 * Expects a Google Login object to be passed in as a prop - Will conditionally show
 * login button or profile/logout button depending on state.
 *
 * @todo add menu to the header to include auth specific items for admin, etc...
 *
 */

// Core Libraries
import React from "react";
import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import PlexLogo from "../plex-logo.svg";
import Box from "@mui/material/Box";

import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const styles = {
  flex: {
    flex: 1,
  },
  appBar: {
    backgroundColor: "#3C434B",
  },
  logo: {
    maxHeight: "100px",
    display: "inline-block",
  },
};

const Header = (props: {
  classes: { appBar: string | undefined; flex: string | undefined };
  children: React.ReactNode;
}) => (
  <AppBar position="static" className={props.classes.appBar}>
    <Toolbar>
      <a href={"/"}>
        <Box
          component="img"
          sx={{
            height: "40px",
            paddingRight: "20px",
          }}
          alt="Plex Logo"
          src={PlexLogo}
        />
      </a>
      <Typography variant="h6" color="inherit">
        Partner Facts
      </Typography>
      <div className={props.classes.flex} />
      {props.children}
    </Toolbar>
  </AppBar>
);

export default withStyles(styles)(Header);
