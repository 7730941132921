import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {CircularProgress, Container, Paper, Tab, Tabs} from "@mui/material";
import {ContractGrid} from "../contract/ContractGrid";
import {ContactGrid} from "../contact/ContactGrid";
import {useParams} from "react-router-dom";
import PartnerDetails from "./PartnerDetails";
import Box from "@mui/material/Box";
import {AccountCircle, Assignment} from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';

function PartnerPage({user}: any) {
    type IdParams = {
        partnerId: string;
    };
    const { partnerId } = useParams<IdParams>();
    const [activeTab, setActiveTab] = useState(0);
    const [initialPartnerLoaded, setInitialPartnerLoaded] = useState(false);
    const allowDelete: string | null = localStorage.getItem("allowDelete");

    const handleTabChange = (event: any, newValue: React.SetStateAction<number>) => {
        setActiveTab(newValue);
    };

    const handlePartnerLoaded = () => {
        setInitialPartnerLoaded(true)
    }
    return (
        <>
            <PartnerDetails user={user} onPartnerLoaded={handlePartnerLoaded}/>
            {initialPartnerLoaded &&
            <Container maxWidth={'xl'}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label={<div><Assignment
                        style={{marginBottom: -7, marginRight: 5}}/>Contracts
                    </div>}
                         tabIndex={0}/>
                    <Tab label={<div><AccountCircle
                        style={{marginBottom: -7, marginRight: 5}}/>Contacts
                    </div>} tabIndex={1}/>
                </Tabs>
                {activeTab === 0 &&
                <Box>
                    <ContractGrid user={user} selectPartner={partnerId} embed showTitle={false} allowDelete={allowDelete === "true" ? true : false}/>
                </Box>}
                {activeTab === 1 &&
                <Box>
                    <ContactGrid user={user} selectPartner={partnerId} embed showTitle={false}/>
                </Box>}
            </Container>
            }
        </>
    );
}


PartnerPage.propTypes = {
    user: PropTypes.object.isRequired
};

export default PartnerPage;
