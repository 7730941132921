import { Tab, Tabs } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import escapeRegExp from "./escapeRegExp";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function useTabs({
  titles: tabTitles,
  paramName = "tab",
}: {
  paramName?: string;
  titles: readonly string[];
}) {
  const location = useLocation();

  const selectedTab = useMemo(() => {
    const paramNameRegExp = new RegExp(
      `${escapeRegExp(`${paramName}-`)}(\\d+)`
    );
    const match = location.hash.match(paramNameRegExp);
    return match ? parseInt(match[1], 10) : 0;
  }, [location.hash, paramName]);

  const navigate = useNavigate();

  const setSelectedTab = useCallback(
    (nextSelectedTab: number) => {
      navigate(nextSelectedTab ? `#${paramName}-${nextSelectedTab}` : "#");
    },
    [navigate, paramName]
  );

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );

  const tabs = useMemo(() => {
    return (
      <Tabs value={selectedTab} onChange={handleChange} centered>
        {tabTitles.map((tabTitle, index) => {
          return <Tab key={index} label={tabTitle} {...a11yProps(index)} />;
        })}
      </Tabs>
    );
  }, [handleChange, selectedTab, tabTitles]);

  return {
    tabs,
    selectedTab,
    setSelectedTab,
  };
}
