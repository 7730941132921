/* eslint-disable eqeqeq */
/**
 * Component to provide look-up list in a drop-down for any relationship
 *
 * @author Todd Hay
 * @copyright 2020
 *
 * @todo Cache results so I don't need to hit the DB everytime...
 * @todo Add muliple lookup control types
 * @todo Figure out how to pass in a filter
 * @todo Sort list
 */

// Libraries
//import React, { Component, useState, useEffect, Fragment } from 'react';
//import Autocomplete from '@mui/lab/Autocomplete'
import React, { Component } from "react";

// Local
import { getLookUp } from "../utils/dataAccess";
import { CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

class LookUp extends Component<any, any> {
  state = {
    loading: true,
    inputValue: "",
    selected: null,
    value: undefined as any,
    lookUpTable: undefined as any,
  };

  constructor(props: { options: any; uiSchema: any; onChange: any, value:any }) {
    super(props);
    this.state.value = props.value;
  }

  componentDidMount() {
    this.onLookup();
  }

  onLookup = () => {
    this.setState({ loading: true });
    //console.log(this.props.options.filter);
    getLookUp(
      this.props.options.table,
      this.props.options.displayField,
      this.props.options.filter
    )
      .then((res) => {
        const sel = res.find((e: any) => e.value == this.state.value);
        //console.log(res);
        this.setState({
          loading: false,
          lookUpTable: res,
          selected: sel,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  onInputChange = (newInput: any) => {
    //console.log(newInput);
    const sel = this.state.lookUpTable?.find((e: any) => e.name === newInput);
    this.setState({ selected: sel });
    this.props.onChange(sel.value);
  };

  onChange = (newValue: any) => {
    //console.log(newValue);
    const sel = this.state.lookUpTable?.find(
      (e: { value: any }) => e.value == newValue
    );
    this.setState({ selected: sel });
    this.props.onChange(newValue ? newValue.value : null);
  };

  render() {
    if (this.state.loading) {
      return (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      );
    }
    this.props.uiSchema.type = "string";
    return (
      <>
        <Autocomplete
          aria-invalid="false"
          id={`${this.props.schema.title}-select`}
          className="MuiSelect-root MuiSelect-select"
          value={this.state.selected}
          onChange={(e, newValue) => this.onChange(newValue)}
          //onInputChange={(e, newValue) => this.onInputChange(newValue)}
          options={this.state.lookUpTable}
          getOptionLabel={(option: any) => {
            //console.log(option);
            return option?.name || '';
          }}
          //getOptionSelected={(item, value) => {
          //console.log(item, value, value == item.value);
          //return (value == item.value);
          //}}
          renderInput={(params) => (
            <TextField {...params} label={this.props.schema.title} />
          )}
        />
      </>
    );
  }
}

export default LookUp;
