export const partnerUiSchema = {
    uid: {
        "ui:widget": "hidden",
        "ui:description": ''
    },
    lastEditedBy: {"ui:disabled": true},
    lastEdited: {"ui:disabled": true},
    dateAdded: {"ui:disabled": true},
    notes: {
        additionalItems: {
            "ui:widget": "textarea",
            "ui:placeholder": "Enter a note",
            "ui:rows": 2
        },
        "ui:options": {
            readonly: true,
            orderable: false,
            removable: false
        }
    },
    active: {"ui:widget": 'hidden', "ui:description": null},
    "ui:order": ["partner", "type", "owner", "dateAdded", "notes", "lastEdited", "lastEditedBy", "active", "uid"]
};